import React from 'react'
import PrivacyPage from '../components/PrivacyPage'

const Privacy = () => {
	return (
		<>
			<PrivacyPage />
		</>
	)
}
export default Privacy
