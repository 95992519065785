import React from 'react'
import InfoPage from '../components/InfoPage'

const Info = () => {
	return (
		<>
			<InfoPage />
		</>
	)
}
export default Info
