import React, { useEffect, useState } from 'react'
import { Container, Flex, Box, Text, Image, useToast } from '@chakra-ui/react'
import styles from './QuestionsPage.module.scss'
import { localGetItem, localSetItem } from '../hooks/LocalStorage'
import { useNavigate } from 'react-router-dom'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import dataQuestions from '../data/questions.json'

import GetAvatars from './GetAvatars'

import navbarWave from '../assets/navbar-wave-bottom.svg'
import navbarWaveOrange from '../assets/navbar-wave-bottom-orange.svg'
import line from '../assets/line.svg'
import correct from '../assets/correct.svg'
import incorrect from '../assets/incorrect.svg'
import scoreBorder from '../assets/score.svg'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faXmark } from '@fortawesome/pro-solid-svg-icons'
import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons'

import { getMp4, getWebm } from './GetVideos'

import axios from 'axios'

const QuestionsPage = () => {
	const navigate = useNavigate()

	useEffect(() => {
		if (!localGetItem('questions')) {
			navigate('/spelen')
		}
		if (localGetItem('resultEnd')) {
			if (JSON.parse(localGetItem('resultEnd'))) {
				navigate('/spelen/uitslag')
			}
		}
	}, [])

	return (
		<>
			<Questions />
		</>
	)
}

const Questions = () => {
	if (localGetItem('nickName') && localGetItem('selectedAvatar') && localGetItem('playerTwoName') && localGetItem('playerTwoAvatar') && !localGetItem('resultEnd')) {
		const navigate = useNavigate()
		const [score, setScore] = useState(0)
		const [atQuestion, setAtQuestion] = useState(0)
		const [atCurrentQuestion, setAtCurrentQuestion] = useState(1)
		const [questionsMax, setQuestionsMax] = useState(0)
		const [transitionName] = useState('left')
		const avatars = GetAvatars()
		const playerOneNickname = localGetItem('nickName')
		const playerOneAvatar = JSON.parse(localGetItem('selectedAvatar'))
		const playerTwoNickname = localGetItem('playerTwoName')
		const playerTwoAvatar = JSON.parse(localGetItem('playerTwoAvatar'))
		const videosMp4 = getMp4()
		const videosWebm = getWebm()
		const [playing, setPlaying] = useState(true)
		const [answerGiven, setAnswerGiven] = useState(false)
		const [answerSet, setAnswerSet] = useState({})
		const [nextQuestionTrigger, setNextQuestionTrigger] = useState(false)
		const [answers, setAnswers] = useState([])
		const [showResult, setShowResult] = useState(false)
		const toast = useToast()

		useEffect(() => {
			let count = 0
			dataQuestions.forEach(question => {
				if (question.type === 'question') {
					count = count + 1
				}
			})
			setQuestionsMax(count)
		}, [])

		useEffect(() => {
			let video = document.getElementById('video')
			if (video) {
				if (playing) {
					video.play()
				} else {
					video.pause()
				}
			}
		}, [playing])

		useEffect(() => {
			setPlaying(true)
			let playTimer = setTimeout(() => setPlaying(false), 10000)
			return () => {
				clearTimeout(playTimer)
			}
		}, [atQuestion])

		const playVideo = () => {
			if (!playing) {
				setPlaying(true)
				setTimeout(() => setPlaying(false), 10000)
			}
		}

		const giveAnswer = (answer, given) => {
			if (!answerGiven) {
				let answerBox = document.getElementById('answerBox')
				let resultBox = document.getElementById('resultBox')
				setAnswerGiven(true)
				answerBox.style.opacity = 0
				setTimeout(() => {
					answerBox.style.display = 'none'
					resultBox.style.display = 'block'
					setTimeout(() => {
						resultBox.style.transform = 'translateY(0)'
					}, 25)
				}, 250)
				if (answer === given) {
					setScore(score + 1)
				}
				setAnswerSet({ 'answer': answer, 'given': given })

				setAnswers((currentState) => [...currentState, { answer: given }])
			}
		}

		const switchQuestion = () => {
			setNextQuestionTrigger(true)
			setAtQuestion(atQuestion + 1)
			setAnswerGiven(false)
			setAnswerSet({})
			setTimeout(() => {
				setNextQuestionTrigger(false)
			}, 250)
		}

		const nextQuestion = () => {
			if (questionsMax === atCurrentQuestion) {
				setShowResult(true)
				axios.post(`${process.env.REACT_APP_BASE_URL}/result`, {
					session_id: localGetItem('sessionId'),
					result: JSON.stringify(answers),
				})
					.then(() => {
						localSetItem('result', JSON.stringify(answers))
						localSetItem('resultEnd', JSON.stringify(true))
						localSetItem('scorePlayer', JSON.stringify(score))
						navigate('/spelen/uitslag')
					}).catch((error) => {
						if (error.response) {
							toast({
								position: 'bottom',
								description: 'Er ging iets mis, probeer het later opnieuw.',
								status: 'error',
								duration: 4000,
							})
							setShowResult(false)
						}
					})
			} else {
				if (!nextQuestionTrigger) {
					switchQuestion()
					setAtCurrentQuestion(atCurrentQuestion + 1)
				}
			}
		}

		const resultNextQuestion = () => {
			if (!nextQuestionTrigger) {
				switchQuestion()
			}
		}

		useEffect(() => {
			localSetItem('answers', JSON.stringify(answers))
		}, [answers])

		return (
			<>
				<Container p={0} maxW='container.sm'>
					<Flex flexDirection="column" minH={'calc(100vh - 3.75rem)'}>
						<Flex w={'100%'} flexWrap={'wrap'} alignItems={'center'} justifyContent={'center'} mb={8}>
							<Box position={'relative'} w={'7.5rem'}>
								<Box w={'3.75rem'} h={'3.75rem'} position={'relative'} ml={'auto'} mr={'auto'}><Box className={styles.score}>{score}</Box><Image w={'100%'} src={avatars[playerOneAvatar].image} alt={avatars[playerOneAvatar].alt} /></Box>
								<Box textAlign={'center'}><Text fontSize={'0.75rem'} fontWeight={'700'} color={'orange_red'}>{playerOneNickname}</Text></Box>
							</Box>
							<Box mx={10} bg={'orange_red'} display={'flex'} alignItems={'center'} justifyContent={'center'} w={'2.25rem'} h={'1.5625rem'}>
								<Text color={'white'} fontWeight={'700'} fontSize={'0.875rem'}>VS</Text>
							</Box>
							<Box position={'relative'} w={'7.5rem'}>
								<Box w={'3.75rem'} h={'3.75rem'} position={'relative'} ml={'auto'} mr={'auto'}><Image w={'100%'} src={avatars[playerTwoAvatar].image} alt={avatars[playerTwoAvatar].alt} /></Box>
								<Box textAlign={'center'}><Text fontSize={'0.75rem'} fontWeight={'700'} color={'orange_red'}>{playerTwoNickname}</Text></Box>
							</Box>
						</Flex>
						<Flex flex={'wrap'} flexDirection="column" position={'relative'} mt={'auto'} minHeight={'calc(100vh - 8.875rem)'} mb={[-7, -7, -14]} w={'100%'}>
							<Box
								backgroundImage={`url(${navbarWaveOrange})`}
								className={styles.wave}
							/>
							<Box className={styles.bottomblockorange} />
							<TransitionGroup>
								<CSSTransition
									key={atQuestion}
									classNames={transitionName}
									timeout={500}
								>
									<Box w={'100%'} pt={[7, 7, 16]} pb={[7, 7, 16]}>
										<Box zIndex={20} position={'relative'} w={'100%'} pl={7} pr={7} pb={7}>
											{dataQuestions[atQuestion].type === 'question' ?
												<Box>
													<Text fontSize={'0.875rem'} color={'white'} fontWeight={'700'} textAlign={'center'}>Vraag {atCurrentQuestion} / {questionsMax}</Text>
													<Text mt={1} fontSize={'1.375rem'} color={'white'} fontWeight={'600'} textAlign={'center'} dangerouslySetInnerHTML={{__html: dataQuestions[atQuestion].question}}></Text>
													<Image mt={4} ml={'auto'} mr={'auto'} src={line} alt={'line'} w={'100%'} maxWidth={'19.375rem'} />
													<Box id='answerBox' transition={'opacity 0.25s ease-in-out'}>
														<Box onClick={playVideo} mt={8} w={'100%'} position={'relative'} className={`${styles.player} ${!playing ? styles.showplayerbtn : ''}`}>
															<Box
																className={styles.btn}
																zIndex={5}
																as='button'
																position={'absolute'}
																top={'50%'}
																left={'50%'}
																transform={'translate(-50%, -50%)'}
																w={'2.875rem'}
																h={'2.875rem'}
																borderRadius={'2.875rem'}
																bg={'rgba(255, 255, 255, .88)'}
																fontSize={'1rem'}
																fontWeight={'700'}
																color={'night_blue'}
																transition={'background-color .25s ease-in-out, color .25s ease-in-out'}
																_hover={{
																	bg: 'night_blue',
																	color: 'white',
																}}
																_active={{
																	bg: 'night_blue',
																	color: 'white',
																}}
																_focus={{
																	boxShadow:
																		'0 0 1px 2px #EB5B21, 0 1px 1px #EB5B21',
																}}
																_focusVisible={{
																	outline: 'none',
																	boxShadow:
																		'0 0 1px 2px #EB5B21, 0 1px 1px #EB5B21',
																}}
															>
																GIF
															</Box>
															<video id='video' autoPlay playsInline muted loop className={styles.video}>
																<source src={videosWebm[dataQuestions[atQuestion].gif]} type='video/webm' />
																<source src={videosMp4[dataQuestions[atQuestion].gif]} type='video/mp4' />
															</video>
														</Box>
														<Flex mt={10} mb={[0, 0, 4]}>
															<Box
																onClick={() => giveAnswer(dataQuestions[atQuestion].answer, 1)}
																as='button'
																gap={'0.6875rem'}
																mr={2.5}
																zIndex={'10'}
																display={'flex'}
																alignItems={'center'}
																justifyContent={'center'}
																w={['100%', '100%', 'calc(50% - 0.75rem)']}
																height='3.5rem'
																borderRadius='0.4375rem'
																fontSize='1.25rem'
																fontWeight='500'
																border={'0.125rem solid'}
																borderColor={'white'}
																color='white'
																transition={'background-color 0.25s ease-in-out'}
																_hover={{
																	bg: 'white',
																	color: 'orange_red',
																}}
																_active={{
																	color: 'orange_red',
																	bg: 'white',
																}}
																_focus={{
																	boxShadow:
																		'0 0 1px 2px #fff, 0 1px 1px #fff',
																}}
																_focusVisible={{
																	outline: 'none',
																	boxShadow:
																		'0 0 1px 2px #fff, 0 1px 1px #fff',
																}}
															>
																<FontAwesomeIcon icon={faCheck} /> Echt
															</Box>
															<Box
																onClick={() => giveAnswer(dataQuestions[atQuestion].answer, 2)}
																as='button'
																gap={'0.6875rem'}
																ml={2.5}
																zIndex={'10'}
																display={'flex'}
																alignItems={'center'}
																justifyContent={'center'}
																w={['100%', '100%', 'calc(50% - 0.75rem)']}
																height='3.5rem'
																borderRadius='0.4375rem'
																fontSize='1.25rem'
																fontWeight='500'
																border={'0.125rem solid'}
																borderColor={'white'}
																color='white'
																transition={'background-color 0.25s ease-in-out'}
																_hover={{
																	bg: 'white',
																	color: 'orange_red',
																}}
																_active={{
																	color: 'orange_red',
																	bg: 'white',
																}}
																_focus={{
																	boxShadow:
																		'0 0 1px 2px #fff, 0 1px 1px #fff',
																}}
																_focusVisible={{
																	outline: 'none',
																	boxShadow:
																		'0 0 1px 2px #fff, 0 1px 1px #fff',
																}}
															>
																<FontAwesomeIcon icon={faXmark} /> Nep
															</Box>
														</Flex>
													</Box>
													<Box position={'relative'} mt={[10, 10, 16]} id='resultBox' transition={'transform 0.5s ease-in-out'} transform={'translateY(100vh)'} display={'none'}>
														<Box
															backgroundImage={`url(${navbarWave})`}
															className={styles.wave}
														/>
														<Box className={styles.bottomblockwhite} />
														<Box position={'relative'} zIndex={'10'} pt={8}>
															{answerSet.answer === answerSet.given ?
																<>
																	<Flex>
																		<Flex mr={3} w={'2.0625rem'} h={'2.0625rem'} position={'relative'} alignItems={'center'} justifyContent={'center'} color={'night_blue'}>
																			<Image position={'absolute'} w src={correct} alt='Juist' />
																			<FontAwesomeIcon icon={faCheck} style={{ position: 'relative', zIndex: '10' }} />
																		</Flex>
																		<Text color={'orange_red'} fontSize={'1.25rem'} fontWeight={'700'}>Jouw antwoord: {answerSet.given === 1 ? 'Echt' : 'Nep'}</Text>
																	</Flex>
																	<Text pt={3} color={'black'} fontSize='1rem' dangerouslySetInnerHTML={{__html: dataQuestions[atQuestion].info}}></Text>
																</>
																:
																<>
																	<Flex>
																		<Flex mr={3} w={'2.0625rem'} h={'2.0625rem'} position={'relative'} alignItems={'center'} justifyContent={'center'} color={'orange_red'}>
																			<Image position={'absolute'} w src={incorrect} alt='Onjuist' />
																			<FontAwesomeIcon icon={faXmark} style={{ position: 'relative', zIndex: '10' }} />
																		</Flex>
																		<Text color={'orange_red'} fontSize={'1.25rem'} fontWeight={'700'}>Jouw antwoord: {answerSet.given === 1 ? 'Echt' : 'Nep'}</Text>
																	</Flex>
																	<Text pt={3} color={'black'} fontSize='1rem' dangerouslySetInnerHTML={{__html: dataQuestions[atQuestion].info}}></Text>
																</>
															}
															<Box
																onClick={() => nextQuestion()}
																className={`${styles.btn} ${showResult ? styles.loading : ''}`}
																disabled={showResult}
																as='button'
																mt={10}
																zIndex={'10'}
																display={'flex'}
																alignItems={'center'}
																justifyContent={'center'}
																w={['100%', '100%', 'calc(50% - 0.75rem)']}
																height='3.5rem'
																borderRadius='0.4375rem'
																fontSize='1.125rem'
																fontWeight='500'
																border={'0.125rem solid'}
																borderColor={'orange_red'}
																color='white'
																bg='orange_red'
																transition={'background-color 0.25s ease-in-out'}
																_hover={{
																	bg: 'white',
																	color: 'orange_red',
																	borderColor: 'orange_red',
																}}
																_active={{
																	color: 'orange_red',
																	bg: 'white',
																	borderColor: 'orange_red',
																}}
																_focus={{
																	boxShadow:
																		'0 0 1px 2px #fff, 0 1px 1px #fff',
																}}
																_focusVisible={{
																	outline: 'none',
																	boxShadow:
																		'0 0 1px 2px #fff, 0 1px 1px #fff',
																}}
															>
																{questionsMax === atCurrentQuestion ?
																	<span><div className={styles.txt}>Uitslag</div> <div className={styles.loader}><FontAwesomeIcon icon={faSpinnerThird} /></div></span>
																	:
																	'Verder'
																}
															</Box>
														</Box>
													</Box>
												</Box>
												:
												<Box w={'100%'}>
													<Text fontSize={'1.75rem'} fontWeight={'700'} textAlign={'center'} color={'white'}>Check de tussenstand</Text>
													<Text mt={2} fontSize={'1.375rem'} fontWeight={'600'} textAlign={'center'} color={'white'}>De battle is in volle gang! Maar… wie gaat er aan kop?</Text>
													<Flex flexDirection={'column'} alignContent={'center'} justifyContent={'center'}>
														<Box mx={'auto'} mt={6} w={'10.3125rem'} h={'10.3125rem'} position={'relative'}>
															<Image src={scoreBorder} alt='Score' position={'absolute'} />
															<Image src={avatars[playerOneAvatar].image} alt='Avatar' position={'relative'} zIndex={'5'} p={1} />
															<Box className={styles.scorebig}>{score}</Box>
														</Box>
													</Flex>
													<Text mt={8} fontSize={'1.375rem'} fontWeight={'600'} textAlign={'center'} color={'white'}>{playerOneNickname}</Text>
													<Text mt={8} fontSize={'1.25rem'} fontWeight={'700'} textAlign={'center'} color={'white'}>Tijd voor een break</Text>
													<Text mt={3} fontSize={'1.125rem'} textAlign={'center'} color={'white'} dangerouslySetInnerHTML={{__html: dataQuestions[atQuestion].info}}></Text>
													<Box
														onClick={() => resultNextQuestion()}
														as='button'
														mt={10}
														ml={'auto'}
														mr={'auto'}
														zIndex={'10'}
														display={'flex'}
														alignItems={'center'}
														justifyContent={'center'}
														w={['100%', '100%', 'calc(50% - 0.75rem)']}
														height='3.5rem'
														borderRadius='0.4375rem'
														fontSize='1.125rem'
														fontWeight='500'
														border={'0.125rem solid'}
														borderColor={'white'}
														color='white'
														bg='orange_red'
														transition={'background-color 0.25s ease-in-out'}
														_hover={{
															bg: 'white',
															color: 'orange_red',
															borderColor: 'white',
														}}
														_active={{
															color: 'orange_red',
															bg: 'white',
															borderColor: 'white',
														}}
														_focus={{
															boxShadow:
																'0 0 1px 2px #fff, 0 1px 1px #fff',
														}}
														_focusVisible={{
															outline: 'none',
															boxShadow:
																'0 0 1px 2px #fff, 0 1px 1px #fff',
														}}
													>
														Verder
													</Box>
												</Box>
											}
										</Box>
									</Box>
								</CSSTransition>
							</TransitionGroup>
						</Flex>
					</Flex>
				</Container>
			</>
		)
	}
}

export default QuestionsPage
