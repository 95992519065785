import React from 'react'
import TipsAndTricksPage from '../components/TipsAndTricksPage'

const TipsAndTricks = () => {
	return (
		<>
			<TipsAndTricksPage />
		</>
	)
}
export default TipsAndTricks
