import React from 'react'
import { Container, Flex, Box, Text, Heading } from '@chakra-ui/react'
import styles from './Page.module.scss'
import { Link } from 'react-router-dom'

import navbarWave from '../assets/navbar-wave-bottom.svg'
import navbarWavBlue from '../assets/navbar-wave-bottom-blue.svg'

const TipsAndTricksPage = () => {
	return (
		<>
			<Container p={0} maxW='container.sm' className={styles.pagecontainer} position={'relative'}>
				<Flex flexDirection="column" minH={'calc(100vh - 3.75rem)'}>
					<Flex flex={'wrap'} flexDirection="column" position={'relative'} w={'100%'}>
						<Box
							backgroundImage={`url(${navbarWavBlue})`}
							className={styles.wave}
						/>
						<Box className={styles.bottomblockblue} />
						<Box w={'100%'} pt={[7, 7, 16]} pb={[4, 4, 16]}>
							<Box zIndex={20} position={'relative'} w={'100%'} pl={7} pr={7} pb={7}>
								<Box>
									<Heading color={'white'} fontSize={'1.75rem'} fontWeight={'700'} mb={4}>Wat is sexting?</Heading>
									<Text color={'night_blue'} fontStyle={'1.25rem'} mb={4}>Sexting is het versturen van seksueel getinte berichten, foto&rsquo;s of video&rsquo;s. Heel veel mensen doen aan sexting. Daar is niks mis mee. Tenminste, als het gebeurt met toestemming en zonder druk of dwang.</Text>
									<Text color={'night_blue'} fontStyle={'1.25rem'}>Doe je aan sexting? Houd dan deze tips in je achterhoofd. <Link style={{textDecoration: 'underline'}} to="/tips-en-tricks">Tips & Tricks</Link></Text>
								</Box>
							</Box>
						</Box>
					</Flex>
					<Flex className={styles.blockbottom} flex={'wrap'} flexDirection="column" position={'relative'} w={'100%'} mt={'0.5625rem'}>
						<Box
							backgroundImage={`url(${navbarWave})`}
							className={styles.wave}
						/>
						<Box className={styles.bottomblockwhite} />
						<Box w={'100%'} pt={[7, 7, 16]} pb={[7, 7, 16]}>
							<Box zIndex={20} position={'relative'} w={'100%'} pl={7} pr={7}>
								<Box mt={1}>
									<Heading color={'night_blue'} fontSize={'1.75rem'} fontWeight={'700'} mb={4}>Risico&rsquo;s van sexting</Heading>
									<Text color={'night_blue'} fontStyle={'1.25rem'} mb={8}>Maar aan sexting kleven ook risico&rsquo;s. Het kan gebeuren dat een naaktfoto wordt doorgestuurd. Dat heet shame sexting. Iemand kan ook onder druk worden gezet om bepaalde foto&rsquo;s te maken of versturen. Dat is sextortion. Shame sexting en sextortion kunnen grote gevolgen hebben.</Text>
									<Heading color={'night_blue'} fontSize={'1.75rem'} fontWeight={'700'} mb={4}>Wat is grooming?</Heading>
									<Text color={'night_blue'} fontStyle={'1.25rem'}>Grooming is een vorm van online kinderlokken. Groomers doen zich vaak online voor als iemand anders en winnen het vertrouwen van een jongere. Als ze de jongere uiteindelijk hebben overtuigd om pikante foto&rsquo;s of video&rsquo;s van zichzelf te sturen, gebruiken ze deze beelden om de jongere te chanteren. Grooming is strafbaar.</Text>
								</Box>
							</Box>
						</Box>
					</Flex>
				</Flex>
			</Container>
		</>
	)
}
export default TipsAndTricksPage
