import React, { useState, useEffect } from 'react'
import {
	Route,
	useLocation,
	Routes
} from 'react-router-dom'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import ReactGA from 'react-ga4'
import './Switch.scss'

import Home from '../pages/Home'
import Disclaimer from '../pages/Disclaimer'
import Play from '../pages/Play'
import Questions from '../pages/Questions'
import Result from '../pages/Result'
import TipsAndTricks from '../pages/TipsAndTricks'
import Info from '../pages/Info'
import Privacy from '../pages/Privacy'

ReactGA.initialize('G-NR33P1XWDY')

const Switch = () => {
	const location = useLocation()
	const [transitionName] = useState('next')

	useEffect(() => {
		ReactGA.send({ hitType: 'pageview', page: location.pathname })
	}, [location])

	return (
		<TransitionGroup>
			<CSSTransition
				key={location.pathname}
				classNames={transitionName}
				timeout={500}
			>
				<div>
					<Routes location={location}>
						<Route exact path='/' element={<Home />} />
						<Route path='/disclaimer' element={<Disclaimer />} />
						<Route path='/spelen' element={<Play />} />
						<Route path='/spelen/vragen' element={<Questions />} />
						<Route path='/spelen/uitslag' element={<Result />} />
						<Route path='/tips-en-tricks' element={<TipsAndTricks />} />
						<Route path='/informatie' element={<Info />} />
						<Route path='/privacyvoorwaarden' element={<Privacy />} />
					</Routes>
				</div>
			</CSSTransition>
		</TransitionGroup>
	)
}

export default Switch
