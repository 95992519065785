export const localSetItem = (keyName, keyValue) => {
	localStorage.setItem(keyName, keyValue)
}

export const localGetItem = (keyName) => {
	if (localStorage.getItem(keyName)) {
		return localStorage.getItem(keyName)
	} else {
		return false
	}
}
export const localRemoveItem = (keyName) => {
	if (!localStorage.getItem(keyName)) return
	localStorage.removeItem(keyName)
}
