import gif01Mp4 from '../assets/gifs/1.mp4'
import gif02Mp4 from '../assets/gifs/2.mp4'
import gif03Mp4 from '../assets/gifs/3.mp4'
import gif04Mp4 from '../assets/gifs/4.mp4'
import gif05Mp4 from '../assets/gifs/5.mp4'
import gif06Mp4 from '../assets/gifs/6.mp4'
import gif07Mp4 from '../assets/gifs/7.mp4'
import gif08Mp4 from '../assets/gifs/8.mp4'
import gif09Mp4 from '../assets/gifs/9.mp4'
import gif10Mp4 from '../assets/gifs/10.mp4'
import gif11Mp4 from '../assets/gifs/11.mp4'
import gif12Mp4 from '../assets/gifs/12.mp4'
import gif13Mp4 from '../assets/gifs/13.mp4'
import gif14Mp4 from '../assets/gifs/14.mp4'
import gif15Mp4 from '../assets/gifs/15.mp4'
import gif16Mp4 from '../assets/gifs/16.mp4'
import gif17Mp4 from '../assets/gifs/17.mp4'
import gif18Mp4 from '../assets/gifs/18.mp4'
import gif19Mp4 from '../assets/gifs/19.mp4'
import gif20Mp4 from '../assets/gifs/20.mp4'

import gif01Webm from '../assets/gifs/1.webm'
import gif02Webm from '../assets/gifs/2.webm'
import gif03Webm from '../assets/gifs/3.webm'
import gif04Webm from '../assets/gifs/4.webm'
import gif05Webm from '../assets/gifs/5.webm'
import gif06Webm from '../assets/gifs/6.webm'
import gif07Webm from '../assets/gifs/7.webm'
import gif08Webm from '../assets/gifs/8.webm'
import gif09Webm from '../assets/gifs/9.webm'
import gif10Webm from '../assets/gifs/10.webm'
import gif11Webm from '../assets/gifs/11.webm'
import gif12Webm from '../assets/gifs/12.webm'
import gif13Webm from '../assets/gifs/13.webm'
import gif14Webm from '../assets/gifs/14.webm'
import gif15Webm from '../assets/gifs/15.webm'
import gif16Webm from '../assets/gifs/16.webm'
import gif17Webm from '../assets/gifs/17.webm'
import gif18Webm from '../assets/gifs/18.webm'
import gif19Webm from '../assets/gifs/19.webm'
import gif20Webm from '../assets/gifs/20.webm'

export const getMp4 = () => {
	const videosMp4 = [
		gif01Mp4,
		gif02Mp4,
		gif03Mp4,
		gif04Mp4,
		gif05Mp4,
		gif06Mp4,
		gif07Mp4,
		gif08Mp4,
		gif09Mp4,
		gif10Mp4,
		gif11Mp4,
		gif12Mp4,
		gif13Mp4,
		gif14Mp4,
		gif15Mp4,
		gif16Mp4,
		gif17Mp4,
		gif18Mp4,
		gif19Mp4,
		gif20Mp4,
	]
	return videosMp4
}

export const getWebm = () => {
	const videosWebm = [
		gif01Webm,
		gif02Webm,
		gif03Webm,
		gif04Webm,
		gif05Webm,
		gif06Webm,
		gif07Webm,
		gif08Webm,
		gif09Webm,
		gif10Webm,
		gif11Webm,
		gif12Webm,
		gif13Webm,
		gif14Webm,
		gif15Webm,
		gif16Webm,
		gif17Webm,
		gif18Webm,
		gif19Webm,
		gif20Webm,
	]
	return videosWebm
}
