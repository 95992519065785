import React, { useEffect, useState, useRef } from 'react'
import { Container, Flex, Box, Text, Image, Accordion, AccordionItem, AccordionButton, AccordionPanel, useToast, LinkBox, LinkOverlay } from '@chakra-ui/react'
import styles from './ResultPage.module.scss'
import { localGetItem, localSetItem, localRemoveItem } from '../hooks/LocalStorage'
import { useNavigate } from 'react-router-dom'
import GetAvatars from './GetAvatars'
import dataQuestions from '../data/questions.json'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleUp, faAngleDown, faCheck, faXmark } from '@fortawesome/pro-solid-svg-icons'
import { faLightbulbOn, faCircleInfo, faSpinnerThird, faRefresh, faRight } from '@fortawesome/pro-regular-svg-icons'

import navbarWave from '../assets/navbar-wave-bottom.svg'
import navbarWaveOrange from '../assets/navbar-wave-bottom-orange.svg'
import scoreBorder from '../assets/score.svg'
import correct from '../assets/correct.svg'
import incorrect from '../assets/incorrect.svg'
import crown from '../assets/crown.svg'

import axios from 'axios'

const ResultPage = () => {
	const navigate = useNavigate()

	useEffect(() => {
		if (!localGetItem('resultEnd') && !localGetItem('scorePlayer') && !localGetItem('selectedAvatar') && !localGetItem('nickName') && !localGetItem('playerTwoName') && !localGetItem('playerTwoAvatar')) {
			navigate('/spelen')
		}
	}, [])

	return (
		<>
			<Result />
		</>
	)
}

const Result = () => {
	if (localGetItem('resultEnd') && localGetItem('scorePlayer') && localGetItem('selectedAvatar') && localGetItem('nickName') && localGetItem('playerTwoName') && localGetItem('playerTwoAvatar')) {
		const navigate = useNavigate()
		const avatars = GetAvatars()
		const [scorePlayerOne] = useState(localGetItem('scorePlayer'))
		const [scorePlayerTwo, setScorePlayerTwo] = useState('?')
		const [playerOneAvatar] = useState(JSON.parse(localGetItem('selectedAvatar')))
		const [playerOneNickname] = useState(localGetItem('nickName'))
		const [playerTwoNickname] = useState(localGetItem('playerTwoName'))
		const [playerTwoAvatar] = useState(JSON.parse(localGetItem('playerTwoAvatar')))
		const [resultTitle, setResultTitle] = useState(`Het lijkt erop alsof ${playerTwoNickname} nog niet klaar is.`)
		const [resultIntro, setResultIntro] = useState('Wacht tot jullie beide klaar zijn en refresh de pagina om de uitslag samen te bekijken.')
		const [questions, setQuestions] = useState([])
		const [result] = useState(JSON.parse(localGetItem('result')))
		const [resultPlayerTwo, setResultPlayerTwo] = useState(false)
		const [refreshResult, setRefreshResult] = useState(false)
		const [playerOneWon, setPlayerOneWon] = useState(false)
		const [playerTwoWon, setPlayerTwoWon] = useState(false)
		const [getResultRender, setGetResultRender] = useState(false)
		const [mediaMastersSessionId, setMediaMastersSessionId] = useState(localGetItem('mediaMastersSessionId'))
		const mediaMastersRedirectUrl = `${process.env.REACT_APP_MEDIAMASTERS_URL}?sessionId=${mediaMastersSessionId}`
		const sticky = useRef(null)
		const toast = useToast()

		const endResult = () => {
			if (JSON.parse(scorePlayerOne) === scorePlayerTwo) {
				setResultTitle('Gelijkspel!')
				setPlayerOneWon(false)
				setPlayerTwoWon(false)
			} else {
				if (scorePlayerOne > scorePlayerTwo) {
					setResultTitle(`${playerOneNickname} heeft gewonnen!`)
					setPlayerOneWon(true)
					setPlayerTwoWon(false)
				} else {
					setResultTitle(`${playerTwoNickname} heeft gewonnen!`)
					setPlayerOneWon(false)
					setPlayerTwoWon(true)
				}
			}
			setResultIntro('Vergelijk snel jullie antwoorden!')
		}

		const refresh = () => {
			setRefreshResult(true)
			const sessionId = localGetItem('sessionId')
			axios.get(`${process.env.REACT_APP_BASE_URL}/user/${sessionId}/opponent`)
				.then((response) => {
					if (response.data.data.result !== null) {
						setResultPlayerTwo(JSON.parse(response.data.data.result.questions))
					} else {
						setRefreshResult(false)
						toast({
							position: 'bottom',
							description: 'Nog geen resultaat beschikbaar.',
							status: 'error',
							duration: 4000,
						})
					}
				}).catch((error) => {
					if (error.response) {
						toast({
							position: 'bottom',
							description: 'Er ging iets mis, probeer het later opnieuw.',
							status: 'error',
							duration: 4000,
						})
						setRefreshResult(false)
					}
				})
		}

		const unsetMediaMastersSessionId = () => {
			localRemoveItem('mediaMastersSessionId')
			setMediaMastersSessionId(undefined)
		}


		const tipsAndTricks = () => {
			navigate('/tips-en-tricks')
		}
		
		const info = () => {
			navigate('/informatie')
		}

		useEffect(() => {
			if (resultPlayerTwo) {
				let score = 0
				questions.forEach((question, index) => {
					resultPlayerTwo[index].answer === question.answer ? score++ : score
				})
				setScorePlayerTwo(score)
				localSetItem('scorePlayerTwo', JSON.stringify(score))
				localSetItem('resultPlayerTwo', JSON.stringify(resultPlayerTwo))
			}
		}, [resultPlayerTwo])

		useEffect(() => {
			if (scorePlayerTwo === '?') return
			endResult()
		}, [scorePlayerOne, scorePlayerTwo])

		useEffect(() => {
			if (localGetItem('scorePlayerTwo') && localGetItem('resultPlayerTwo')) {
				setScorePlayerTwo(localGetItem('scorePlayerTwo'))
				setResultPlayerTwo(JSON.parse(localGetItem('resultPlayerTwo')))
			} else {
				if (!getResultRender) {
					refresh()
					setGetResultRender(true)
				}
			}
		}, [])

		useEffect(() => {
			setQuestions([])
			dataQuestions.forEach(question => {
				if (question.type === 'question') {
					setQuestions((currentState) => [...currentState, question])
				}
			})
		}, [])

		useEffect(() => {
			const onScroll = () => {
				if (window.pageYOffset > 0) {
					sticky.current.style.transform = 'translateY(0)'
				} else {
					sticky.current.style.transform = 'translateY(100%)'
				}
			}
			window.removeEventListener('scroll', onScroll)
			window.addEventListener('scroll', onScroll, { passive: true })
			return () => window.removeEventListener('scroll', onScroll)
		}, [])

		const allQuestions = questions.map((item, index) =>
			<AccordionItem key={index} borderTop={0} py={5} borderBottomWidth={'0.5rem !important'} borderBottomColor={'pale_pink'}>
				{({ isExpanded }) => (
					<>
						<AccordionButton bg={'white'} px={0} py={0} _hover={{
							bg: 'white',
						}}>
							<Box flex='1' textAlign='left'>
								<Text fontSize={'1rem'} fontWeight={'700'} color={'orange_red'} dangerouslySetInnerHTML={{ __html: item.question }}></Text>
							</Box>
							{isExpanded ? (
								<Box fontSize={'1.625rem'} color={'orange_red'}><FontAwesomeIcon icon={faAngleUp} /></Box>
							) : (
								<Box fontSize={'1.625rem'} color={'orange_red'}><FontAwesomeIcon icon={faAngleDown} /></Box>
							)}
						</AccordionButton>
						<AccordionPanel px={0} pb={0} pt={5}>
							{result[index].answer === item.answer ?
								<Flex mb={4} alignItems={'center'} flexWrap={'wrap'} w={'100%'}>
									<Flex mr={3} w={'1.3125rem'} h={'1.3125rem'} position={'relative'} alignItems={'center'} justifyContent={'center'} color={'night_blue'} fontSize={'0.875rem'}>
										<Image position={'absolute'} w src={correct} alt='Juist' />
										<FontAwesomeIcon icon={faCheck} style={{ position: 'relative', zIndex: '10' }} />
									</Flex>
									<Text color={'black'} fontSize={'1rem'}>{playerOneNickname}: <strong>Echt</strong></Text>
								</Flex>
								:
								<Flex mb={4} alignItems={'center'} flexWrap={'wrap'} w={'100%'}>
									<Flex mr={3} w={'1.3125rem'} h={'1.3125rem'} position={'relative'} alignItems={'center'} justifyContent={'center'} color={'orange_red'} fontSize={'0.875rem'}>
										<Image position={'absolute'} w src={incorrect} alt='Onjuist' />
										<FontAwesomeIcon icon={faXmark} style={{ position: 'relative', zIndex: '10' }} />
									</Flex>
									<Text color={'black'} fontSize={'1rem'}>{playerOneNickname}: <strong>Nep</strong></Text>
								</Flex>
							}
							{resultPlayerTwo &&
								<>
									{resultPlayerTwo[index].answer === item.answer ?
										<Flex mb={4} alignItems={'center'} flexWrap={'wrap'} w={'100%'}>
											<Flex mr={3} w={'1.3125rem'} h={'1.3125rem'} position={'relative'} alignItems={'center'} justifyContent={'center'} color={'night_blue'} fontSize={'0.875rem'}>
												<Image position={'absolute'} w src={correct} alt='Juist' />
												<FontAwesomeIcon icon={faCheck} style={{ position: 'relative', zIndex: '10' }} />
											</Flex>
											<Text color={'black'} fontSize={'1rem'}>{playerTwoNickname}: <strong>Echt</strong></Text>
										</Flex>
										:
										<Flex mb={4} alignItems={'center'} flexWrap={'wrap'} w={'100%'}>
											<Flex mr={3} w={'1.3125rem'} h={'1.3125rem'} position={'relative'} alignItems={'center'} justifyContent={'center'} color={'orange_red'} fontSize={'0.875rem'}>
												<Image position={'absolute'} w src={incorrect} alt='Onjuist' />
												<FontAwesomeIcon icon={faXmark} style={{ position: 'relative', zIndex: '10' }} />
											</Flex>
											<Text color={'black'} fontSize={'1rem'}>{playerTwoNickname}: <strong>Nep</strong></Text>
										</Flex>
									}
								</>
							}
							<Text fontSize={'1rem'} color={'black'} dangerouslySetInnerHTML={{ __html: item.info }}></Text>
						</AccordionPanel>
					</>
				)}
			</AccordionItem>
		)

		return (
			<>
				<Container p={0} maxW='container.sm' className={styles.pagecontainer} position={'relative'}>
					<Flex flexDirection="column" minH={'calc(100vh - 3.75rem)'}>
						<Flex flex={'wrap'} flexDirection="column" position={'relative'} w={'100%'}>
							<Box
								backgroundImage={`url(${navbarWaveOrange})`}
								className={styles.wave}
							/>
							<Box className={styles.bottomblockorange} />
							<Box w={'100%'} pt={[7, 7, 16]} pb={[4, 4, 16]}>
								<Box zIndex={20} position={'relative'} w={'100%'} pl={7} pr={7} pb={7}>
									<Box>
										<Text textAlign={'center'} fontSize={'1.75rem'} fontWeight={'700'} color={'white'}>Uitslag</Text>
										<Flex w={'100%'} maxW={'20.625rem'} ml={'auto'} mr={'auto'} flexWrap={'wrap'} alignItems={'flex-start'} justifyContent={'center'} mb={10} mt={6}>
											<Box position={'relative'} w={'50%'}>
												<Flex flexDirection={'column'} alignContent={'flex-start'} justifyContent={'center'}>
													<Box mx={'auto'} w={'8.6875rem'} h={'8.6875rem'} position={'relative'}>
														{playerOneWon &&
															<Image position={'absolute'} top={'-1rem'} left={'0'} right={'0'} zIndex={'10'} mx={'auto'} src={crown} alt='Kroon' />
														}
														<Image src={scoreBorder} alt='Score' position={'absolute'} />
														<Image src={avatars[playerOneAvatar].image} alt='Avatar' position={'relative'} zIndex={'5'} p={1} />
														<Box className={styles.scorebig}>{scorePlayerOne}</Box>
													</Box>
												</Flex>
												<Text mt={7} fontSize={'1rem'} fontWeight={'700'} textAlign={'center'} color={'white'}>{playerOneNickname}</Text>
											</Box>
											<Box position={'relative'} w={'50%'}>
												<Flex flexDirection={'column'} alignContent={'flex-start'} justifyContent={'center'}>
													<Box mx={'auto'} w={'8.6875rem'} h={'8.6875rem'} position={'relative'}>
														{playerTwoWon &&
															<Image position={'absolute'} top={'-1rem'} left={'0'} right={'0'} zIndex={'10'} mx={'auto'} src={crown} alt='Kroon' />
														}
														<Image src={scoreBorder} alt='Score' position={'absolute'} />
														<Image src={avatars[playerTwoAvatar].image} alt='Avatar' position={'relative'} zIndex={'5'} p={1} />
														<Box className={styles.scorebig}>{scorePlayerTwo}</Box>
													</Box>
												</Flex>
												<Text mt={7} fontSize={'1rem'} fontWeight={'700'} textAlign={'center'} color={'white'}>{playerTwoNickname}</Text>
											</Box>
										</Flex>
										<Text fontSize={'1.25rem'} fontWeight={'700'} color={'white'} textAlign={'center'}>{resultTitle}</Text>
										<Text mt={3} fontSize={'1.125rem'} color={'white'} textAlign={'center'}>{resultIntro}</Text>
										{!resultPlayerTwo &&
											<Box
												onClick={() => refresh()}
												className={`${styles.btn} ${refreshResult ? styles.loading : ''}`}
												disabled={refreshResult}
												as='button'
												mt={6}
												zIndex={'10'}
												display={'flex'}
												ml={'auto'}
												mr={'auto'}
												alignItems={'center'}
												justifyContent={'center'}
												w={['100%', '100%', 'calc(50% - 0.75rem)']}
												height='3.5rem'
												borderRadius='0.4375rem'
												fontSize='1.125rem'
												fontWeight='500'
												border={'0.125rem solid'}
												borderColor={'white'}
												color='white'
												bg='orange_red'
												transition={'background-color 0.25s ease-in-out'}
												_hover={{
													bg: 'white',
													color: 'orange_red',
													borderColor: 'white',
												}}
												_active={{
													color: 'orange_red',
													bg: 'white',
													borderColor: 'white',
												}}
												_focus={{
													boxShadow:
														'0 0 1px 2px #fff, 0 1px 1px #fff',
												}}
												_focusVisible={{
													outline: 'none',
													boxShadow:
														'0 0 1px 2px #fff, 0 1px 1px #fff',
												}}
											>
												<Flex className={styles.txt}><span style={{ marginRight: '0.6875rem' }}><FontAwesomeIcon icon={faRefresh} /></span> Refresh</Flex> <div className={styles.loader}><FontAwesomeIcon icon={faSpinnerThird} /></div>
											</Box>
										}
										{mediaMastersSessionId &&
											<LinkBox
												onClick={() => unsetMediaMastersSessionId()}
												className={`${styles.btn}`}
												as='button'
												mt={6}
												zIndex={'10'}
												display={'flex'}
												ml={'auto'}
												mr={'auto'}
												alignItems={'center'}
												justifyContent={'center'}
												w={['100%', '100%', '100%']}
												height='3.5rem'
												borderRadius='0.4375rem'
												fontSize='1.125rem'
												fontWeight='500'
												border={'0.125rem solid'}
												borderColor={'white'}
												color='orange_red'
												bg='white'
												transition={'background-color 0.25s ease-in-out'}
												_hover={{
													bg: 'orange_red',
													color: 'white',
													borderColor: 'white',
												}}
												_active={{
													color: 'orange_red',
													bg: 'white',
													borderColor: 'white',
												}}
												_focus={{
													boxShadow:
														'0 0 1px 2px #fff, 0 1px 1px #fff',
												}}
												_focusVisible={{
													outline: 'none',
													boxShadow:
														'0 0 1px 2px #fff, 0 1px 1px #fff',
												}}
											>
												<LinkOverlay href={mediaMastersRedirectUrl} target='_blank'>
													<Flex className={styles.txt}><span style={{ marginRight: '0.6875rem' }}><FontAwesomeIcon icon={faRight} /></span> Dien score in bij MediaMasters!</Flex>
												</LinkOverlay>
											</LinkBox>
										}

									</Box>
								</Box>
							</Box>
						</Flex>
						<Flex className={styles.blockbottom} flex={'wrap'} flexDirection="column" position={'relative'} w={'100%'} mt={'0.5625rem'} mb={['10rem', '10rem', '7rem']}>
							<Box
								backgroundImage={`url(${navbarWave})`}
								className={styles.wave}
							/>
							<Box className={styles.bottomblockwhite} />
							<Box w={'100%'} pt={[7, 7, 16]} pb={[7, 7, 16]}>
								<Box zIndex={20} position={'relative'} w={'100%'} pl={7} pr={7}>
									<Box mt={-3}>
										<Accordion defaultIndex={[0]}>
											{allQuestions}
										</Accordion>
									</Box>
								</Box>
							</Box>
						</Flex>
					</Flex>
				</Container>
				<Box w={'100%'} position={'fixed'} bottom={0} zIndex={'999'} ref={sticky} style={{ transform: 'translateY(100%)' }} transition={'transform .25s ease'}>
					<Container>
						<Box px={[3, 3, 0]} py={3}>
							<Box
								onClick={tipsAndTricks}
								as='button'
								zIndex={'10'}
								display={'flex'}
								alignItems={'center'}
								justifyContent={'center'}
								gap={'0.6875rem'}
								mb={2}
								w={'100%'}
								height='3.5rem'
								borderRadius='0.4375rem'
								fontSize='1.125rem'
								fontWeight='500'
								bg='night_blue'
								color='white'
								transition={'background-color 0.25s ease-in-out'}
								_hover={{ bg: 'night_blue_dark' }}
								_active={{
									bg: 'night_blue_dark',
								}}
								_focus={{
									boxShadow:
										'0 0 1px 2px #EB5B21, 0 1px 1px #EB5B21',
								}}
								_focusVisible={{
									outline: 'none',
									boxShadow:
										'0 0 1px 2px #EB5B21, 0 1px 1px #EB5B21',
								}}
							>
								<FontAwesomeIcon icon={faLightbulbOn} /> Tips &amp; Tricks
							</Box>
							<Box
								onClick={info}
								as='button'
								zIndex={'10'}
								display={'flex'}
								alignItems={'center'}
								justifyContent={'center'}
								gap={'0.6875rem'}
								mb={4}
								w={'100%'}
								height='3.5rem'
								borderRadius='0.4375rem'
								fontSize='1.125rem'
								fontWeight='500'
								bg='night_blue'
								color='white'
								transition={'background-color 0.25s ease-in-out'}
								_hover={{ bg: 'night_blue_dark' }}
								_active={{
									bg: 'night_blue_dark',
								}}
								_focus={{
									boxShadow:
										'0 0 1px 2px #EB5B21, 0 1px 1px #EB5B21',
								}}
								_focusVisible={{
									outline: 'none',
									boxShadow:
										'0 0 1px 2px #EB5B21, 0 1px 1px #EB5B21',
								}}
							>
								<FontAwesomeIcon icon={faCircleInfo} /> Meer informatie
							</Box>
						</Box>
					</Container>
				</Box>
			</>
		)
	}
}

export default ResultPage
