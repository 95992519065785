import React, { useEffect, useState, useRef } from 'react'
import { useMediaQuery, Container, Flex, Box, Text, Input, FormControl, FormLabel, FormErrorMessage, Image, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, useToast } from '@chakra-ui/react'
import styles from './PlayPage.module.scss'
import { localSetItem, localGetItem, localRemoveItem } from '../hooks/LocalStorage'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { useNavigate } from 'react-router-dom'

import navbarWave from '../assets/navbar-wave-bottom.svg'
import navbarWaveBlue from '../assets/navbar-wave-bottom-blue.svg'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinnerThird, faEnvelope, faRefresh, faTrophy, faTriangleExclamation, faBan } from '@fortawesome/pro-regular-svg-icons'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'

import GetAvatars from './GetAvatars'
import Select from '../assets/avatars/select.svg'
import Unknown from '../assets/avatars/unknown.svg'

import axios from 'axios'

const PlayPage = () => {
	return (
		<>
			<PlayGroup />
		</>
	)
}

const PlayGroup = () => {
	const [group, setGroup] = useState('creator')
	const [transitionName] = useState('slide-left')

	useEffect(() => {
		if (localGetItem('resultEnd')) {
			if (JSON.parse(localGetItem('resultEnd'))) {
				setGroup('resultend')
			}
		} else {
			if (localGetItem('gameCode') && localGetItem('readyToPlay')) {
				if (JSON.parse(localGetItem('readyToPlay'))) {
					setGroup('readytoplay')
				} else {
					setGroup('startnewgame')
				}
			}
		}
	}, [group])

	return (
		<>
			<Container p={0} maxW='container.sm' position={'relative'} className={styles.playcontainer}>
				<Flex flexDirection="column" position={'relative'}>
					<Box
						backgroundImage={`url(${navbarWaveBlue})`}
						className={styles.wave}
					/>
					<Box className={styles.bottomblock} />
					<TransitionGroup>
						<CSSTransition
							key={group}
							classNames={transitionName}
							timeout={800}
						>
							{group === 'creator'
								?
								<Flex zIndex={20} position={'relative'} flexWrap='wrap' pt={[7, 7, 16]} pb={[7, 7, 16]} pl={7} pr={7}>
									<PlayerCreator setGroup={setGroup} />
								</Flex>
								: group === 'info'
									?
									<Flex zIndex={20} position={'relative'} flexWrap='wrap' pt={[7, 7, 16]} pb={[7, 7, 16]} pl={7} pr={7}>
										<PlayInfo setGroup={setGroup} />
									</Flex>
									: group === 'startnewgame'
										?
										<Flex zIndex={20} position={'relative'} flexWrap='wrap' pt={[7, 7, 16]} pb={[7, 7, 16]} pl={7} pr={7}>
											<StartNewGame setGroup={setGroup} />
										</Flex>
										: group === 'joingame'
											?
											<Flex zIndex={20} position={'relative'} flexWrap='wrap' pt={[7, 7, 16]} pb={[7, 7, 16]} pl={7} pr={7}>
												<JoinGame setGroup={setGroup} />
											</Flex>
											: group === 'readytoplay'
												?
												<Flex zIndex={20} position={'relative'} flexWrap='wrap' pt={[7, 7, 16]} pb={[7, 7, 16]} pl={7} pr={7}>
													<ReadyToPlay />
												</Flex>
												: group === 'resultend'
													?
													<Flex zIndex={20} position={'relative'} flexWrap='wrap' pt={[7, 7, 16]} pb={[7, 7, 16]} pl={7} pr={7}>
														<ResultEnd setGroup={setGroup} />
													</Flex>
													:
													<p>Error</p>
							}
						</CSSTransition>
					</TransitionGroup>
				</Flex>
			</Container>
		</>
	)
}

const PlayerCreator = ({ setGroup }) => {
	const [nickName, setNickName] = useState('')
	const [isError, setIsError] = useState(false)
	const nickNameInput = useRef(null)
	const [selectedAvatar, setSelectedAvatar] = useState(false)
	const [submitHasError, setSubmitHasError] = useState(false)
	const [submitError, setSubmitError] = useState('')
	const avatars = GetAvatars()

	const handleNickNameChange = (e) => {
		setNickName(e.target.value)
		if (e.target.value.length === 0) {
			setIsError(true)
		} else {
			setIsError(false)
		}
	}

	const submitPlayer = () => {
		if (nickName.length === 0) {
			setIsError(true)
			setSubmitHasError(true)
			setSubmitError('Voer een nickname in')
			nickNameInput.current.focus()
			return
		} else if (selectedAvatar === false) {
			setSubmitHasError(true)
			setSubmitError('Selecteer een avatar')
			return
		} else {
			setSubmitHasError(false)
			setSubmitError('')
		}
		localSetItem('nickName', nickName)
		localSetItem('selectedAvatar', JSON.stringify(selectedAvatar))

		setGroup('info')
	}

	const selectAvatar = (index) => {
		avatars.forEach((avatar, i) => {
			if (i === index) {
				avatar.active = true
				setSelectedAvatar(index)
			}
		})
	}

	const avatarItems = avatars.map((avatar, index) =>
		<Flex key={index} w={'calc(100% / 4)'} alignItems={'center'} justifyContent={'center'} my={2} mx={2}>
			<Box
				as='button'
				onClick={() => selectAvatar(index)}
				borderRadius={'7.0625rem'}
				_focusVisible={{
					outline: 'none',
					boxShadow:
						'0 0 1px 2px #EB5B21, 0 1px 1px #EB5B21',
				}}
			>
				{selectedAvatar === index ? <Flex w={'7.0625rem'} position={'relative'} alignItems={'center'} justifyContent={'center'}><Image position={'absolute'} w={'7.0625rem'} src={Select} alt='Select' /><Image position={'relative'} zIndex={'2'} w={'5.9375rem'} src={avatar.image} alt={avatar.alt} /></Flex> : <Image w={'5.9375rem'} src={avatar.image} alt={avatar.alt} />}
			</Box>
		</Flex>
	)

	useEffect(() => {
		if (localGetItem('nickName')) {
			setNickName(localGetItem('nickName'))
		}

		if (localGetItem('selectedAvatar')) {
			setSelectedAvatar(JSON.parse(localGetItem('selectedAvatar')))
		}
	}, [])

	useEffect(() => {
		setSubmitHasError(false)
		setSubmitError('')
	}, [nickName, selectedAvatar])

	return (
		<>
			<Box zIndex={5} w={'100%'} mb={16}>
				<Text fontSize={'1.75rem'} fontWeight={'700'} color='white'>Maak een speler aan</Text>
				<Text mt={1} fontSize={'1.25rem'} color='night_blue'>Wie ben jij in de sexting battle? Kies hier je nickname en avatar.</Text>
				<FormControl mt={8} isInvalid={isError}>
					<FormLabel htmlFor='nickname' fontSize={'1.125rem'} fontWeight={'700'} color={'night_blue'} border={0}>Kies een nickname</FormLabel>
					<Input
						id='nickname'
						type='text'
						value={nickName}
						onChange={handleNickNameChange}
						h={'3.5rem'}
						bg={'white'}
						borderRadius={'0.4375rem'}
						color={'night_blue'}
						fontSize={'1.125rem'}
						ref={nickNameInput}
					/>
					{isError &&
						<FormErrorMessage>Nickname is vereist</FormErrorMessage>
					}
				</FormControl>
				<Box mt={10}>
					<Text fontSize={'1.125rem'} fontWeight={'700'} color={'night_blue'}>Kies een avatar</Text>
					<Flex maxW={'26.5625rem'} ml={'auto'} mr={'auto'} mt={2} flexWrap={'wrap'}>
						{avatarItems}
					</Flex>
				</Box>
			</Box>
			<Box mt={'auto'} zIndex={5} w={'100%'}>
				{submitHasError &&
					<Box mb={5}>
						<Text fontSize={'1.125rem'} fontWeight={'600'} color={'red.300'}>{submitError}</Text>
					</Box>
				}
				<Box
					onClick={submitPlayer}
					as='button'
					mb={4}
					zIndex={'10'}
					w={'100%'}
					height='3.5rem'
					borderRadius='0.4375rem'
					fontSize='1.125rem'
					fontWeight='500'
					bg='night_blue'
					color='white'
					transition={'background-color 0.25s ease-in-out'}
					_hover={{ bg: 'night_blue_dark' }}
					_active={{
						bg: 'night_blue_dark',
					}}
					_focus={{
						boxShadow:
							'0 0 1px 2px #EB5B21, 0 1px 1px #EB5B21',
					}}
					_focusVisible={{
						outline: 'none',
						boxShadow:
							'0 0 1px 2px #EB5B21, 0 1px 1px #EB5B21',
					}}
				>
					Speler aanmaken
				</Box>
			</Box>
		</>
	)
}

const PlayInfo = ({ setGroup }) => {
	const startNewGameBtn = useRef(null)
	const [startNewGameBtnLoading, setStartNewGameBtnLoading] = useState(false)
	const toast = useToast()

	const startNewGame = () => {
		setStartNewGameBtnLoading(true)

		axios.post(`${process.env.REACT_APP_BASE_URL}/user`, {
			nickname: localGetItem('nickName'),
			avatar_id: localGetItem('selectedAvatar'),
		})
			.then((response) => {
				setGroup('startnewgame')
				localSetItem('gameCode', JSON.stringify(response.data.data.game_id))
				localSetItem('sessionId', response.data.data.session_id)
				localSetItem('readyToPlay', false)
			}).catch((error) => {
				if (error.response) {
					toast({
						position: 'bottom',
						description: 'Er ging iets mis, probeer het later opnieuw.',
						status: 'error',
						duration: 4000,
					})
					setStartNewGameBtnLoading(false)
				}
			})
	}

	const joinGame = () => {
		setGroup('joingame')
	}

	return (
		<>
			<Box zIndex={5} w={'100%'} mb={[12, 12, 16]}>
				<Text fontSize={'1.75rem'} fontWeight={'700'} color='white'>Zo werkt het!</Text>
				<Text mt={1} mb={8} fontSize={'1.25rem'} color='night_blue'>Hoeveel weten jij en je ouders over sexting? Speel samen de sexting battle en ontdek het! In de battle krijg je stellingen te zien over sexting. Bij elke stelling geef je aan of deze volgens jou echt of nep is. </Text>
				<Text mt={1} fontSize={'1.25rem'} color='night_blue'>Nadat je alle stellingen hebt beantwoord, zie je jouw resultaat. Je ziet dan welke stellingen je goed én welke je niet goed hebt beantwoord. Als je wilt, kun je dan ook meer informatie over een onderwerp lezen.</Text>
			</Box>
			<Flex flexDirection="column" position={'relative'} mt={'auto'} mb={[-7, -7, -14]} w={'100%'} pt={[7, 7, 16]} pb={[7, 7, 16]}>
				<Box
					backgroundImage={`url(${navbarWave})`}
					className={styles.wave}
				/>
				<Box className={styles.bottomblockwhite} />
				<Box zIndex={20} position={'relative'} w={'100%'}>
					<Box
						onClick={startNewGame}
						ref={startNewGameBtn}
						className={`${styles.btn} ${startNewGameBtnLoading ? styles.loading : ''}`}
						disabled={startNewGameBtnLoading}
						as='button'
						mb={8}
						mt={2}
						zIndex={'10'}
						w={'100%'}
						height='3.5rem'
						borderRadius='0.4375rem'
						fontSize='1.125rem'
						fontWeight='500'
						bg='night_blue'
						color='white'
						transition={'background-color 0.25s ease-in-out'}
						_hover={{ bg: 'night_blue_dark' }}
						_active={{
							bg: 'night_blue_dark',
						}}
						_focus={{
							boxShadow:
								'0 0 1px 2px #EB5B21, 0 1px 1px #EB5B21',
						}}
						_focusVisible={{
							outline: 'none',
							boxShadow:
								'0 0 1px 2px #EB5B21, 0 1px 1px #EB5B21',
						}}
					>
						<div className={styles.txt}>Ik snap het. Start de battle!</div> <div className={styles.loader}><FontAwesomeIcon icon={faSpinnerThird} /></div>
					</Box>
					<Text fontSize={'1.5rem'} fontWeight={'700'} color='night_blue'>Ben je uitgenodigd?</Text>
					<Text mt={1} mb={5} fontSize={'1.125rem'} color='night_blue'>Vul de code in en doe mee aan een bestaand spel.</Text>
					<Box
						onClick={joinGame}
						as='button'
						zIndex={'10'}
						display={'flex'}
						alignItems={'center'}
						justifyContent={'center'}
						gap={'0.6875rem'}
						mb={3}
						w={'100%'}
						height='3.5rem'
						borderRadius='0.4375rem'
						fontSize='1.125rem'
						fontWeight='500'
						border={'0.125rem solid'}
						borderColor={'night_blue'}
						color='night_blue'
						transition={'background-color 0.25s ease-in-out'}
						_hover={{
							bg: 'night_blue',
							color: 'white',
						}}
						_active={{
							color: 'white',
							bg: 'night_blue',
						}}
						_focus={{
							boxShadow:
								'0 0 1px 2px #EB5B21, 0 1px 1px #EB5B21',
						}}
						_focusVisible={{
							outline: 'none',
							boxShadow:
								'0 0 1px 2px #EB5B21, 0 1px 1px #EB5B21',
						}}
					>
						Meedoen
					</Box>
				</Box>
			</Flex>
		</>
	)
}

const StartNewGame = ({ setGroup }) => {
	const [gameCode, setGameCode] = useState(false)
	const [uniqueGameCode, setUniqueGameCode] = useState([])
	const [playerOneAvatar, setPlayerOneAvatar] = useState(0)
	const [playerOneName, setPlayerOneName] = useState(false)
	const [isLargerThan1280] = useMediaQuery('(min-width: 62em)')
	const [refresh, setRefresh] = useState(false)
	const avatars = GetAvatars()
	const toast = useToast()
	const { isOpen, onOpen, onClose } = useDisclosure()

	useEffect(() => {
		if (localGetItem('gameCode')) {
			const code = localGetItem('gameCode')
			setGameCode(code)
		}
		setPlayerOneAvatar(JSON.parse(localGetItem('selectedAvatar')))
		setPlayerOneName(localGetItem('nickName'))
	}, [])

	useEffect(() => {
		if (gameCode) {
			setUniqueGameCode(gameCode.split(''))
		}
	}, [gameCode])

	const checkGameState = () => {
		setRefresh(true)
		const sessionId = localGetItem('sessionId')
		axios.get(`${process.env.REACT_APP_BASE_URL}/user/${sessionId}/opponent`)
			.then((response) => {
				if (response.data.data.constructor.name === 'Object') {
					localSetItem('playerTwoName', response.data.data.nickname)
					localSetItem('playerTwoAvatar', response.data.data.avatar_id)
					localSetItem('readyToPlay', true)
					setGroup('readytoplay')
				} else {
					toast({
						position: 'bottom',
						description: 'Er is nog geen tegenstander gevonden.',
						status: 'error',
						duration: 4000,
					})
					setRefresh(false)
				}
			}).catch((error) => {
				if (error.response) {
					toast({
						position: 'bottom',
						description: 'Er ging iets mis, probeer het later opnieuw.',
						status: 'error',
						duration: 4000,
					})
					setRefresh(false)
				}
			})
	}

	const uniqueCode = uniqueGameCode.map((letter, index) =>
		<Flex key={index} w={'3.625rem'} h={'3.5rem'} bg={'white'} borderRadius={'0.4375rem'} justifyContent={'center'} alignItems={'center'} fontSize={'1.75rem'} color={'night_blue'} fontWeight={'bold'} textTransform={'uppercase'}>{letter}</Flex>
	)

	const profiles = (
		<Flex mt={9} mb={2} ml={'auto'} mr={'auto'} justifyContent={'space-between'} maxW={'20.625rem'}>
			<Box w={'50%'}>
				<Flex ml={'auto'} mr={'auto'} position={'relative'} w={'8.6875rem'} h={'8.6875rem'} alignItems={'center'} justifyContent={'center'}><Image position={'absolute'} w={'8.6875rem'} src={Select} alt='Select' /><Image position={'relative'} zIndex={2} w={'6.7356rem'} ml={'auto'} mr={'auto'} src={avatars[playerOneAvatar].image} alt={avatars[playerOneAvatar].alt} /></Flex>
				<Box textAlign={'center'}><Text fontSize={'1rem'} fontWeight={'bold'} color={'night_blue'} mt={3}>{playerOneName}</Text></Box>
			</Box>
			<Box w={'50%'}>
				<Box><Image w={'8.6875rem'} ml={'auto'} mr={'auto'} src={Unknown} alt='Unknown' /></Box>
				<Box textAlign={'center'}><Text fontSize={'1rem'} fontWeight={'bold'} color={'night_blue'} mt={3}>Speler 2</Text></Box>
			</Box>
		</Flex>
	)

	const shareWhatsapp = () => {
		if (isLargerThan1280) {
			window.open(`https://web.whatsapp.com/send?text=Unieke spelcode: ${gameCode}`, '_blank')
		} else {
			window.open(`whatsapp://send?text=Unieke spelcode: ${gameCode}`)
		}
	}

	const shareMail = () => {
		window.open(`mailto:?subject=Sexy en Safe: What's nep?&amp;body=Unieke spelcode: ${gameCode}`)
	}

	const modalCancelGame = () => {
		onOpen(true)
	}

	const cancelGame = () => {
		localRemoveItem('sessionId')
		localRemoveItem('gameCode')
		localRemoveItem('readyToPlay')
		onClose(true)
		setGroup('info')
	}

	return (
		<>
			<Box zIndex={5} w={'100%'} mb={[12, 12, 16]}>
				<Text fontSize={'1.75rem'} line-height={'2.25rem'} fontWeight={'700'} color='white'>Je bent bijna klaar om te beginnen!</Text>
				<Text mt={4} mb={2} fontSize={'1.125rem'} fontWeight={'bold'} color='night_blue'>Jouw unieke game code</Text>
				<Flex flexWrap={'wrap'} justifyContent={'space-between'} maxW={'20.625rem'}>
					{uniqueCode}
				</Flex>
			</Box>
			<Flex flexDirection="column" position={'relative'} mt={'auto'} w={'100%'}>
				<Box
					backgroundImage={`url(${navbarWave})`}
					className={styles.wave}
				/>
				<Box className={styles.bottomblockwhite} />
				<Box zIndex={20} position={'relative'} w={'100%'}>
					{profiles}
					<Text mt={8} fontSize={'1.25rem'} fontWeight={'bold'} color={'night_blue'}>Oeps, je bent nog alleen!</Text>
					<Text mt={2} mb={5} fontSize={'1.25rem'} color={'night_blue'}>Om het spel te spelen moet je met z&rsquo;n tweeën zijn. Nodig iemand uit, deel jouw unieke code en refresh de pagina om het spel te starten.</Text>
					<Flex flexWrap={'wrap'}>
						<Box
							onClick={shareWhatsapp}
							as='button'
							zIndex={'10'}
							display={'flex'}
							alignItems={'center'}
							justifyContent={'center'}
							gap={'0.6875rem'}
							mb={[2, 2, 0]}
							ml={[0, 0, '0.75rem']}
							w={['100%', '100%', 'calc(50% - 0.75rem)']}
							height='3.5rem'
							borderRadius='0.4375rem'
							fontSize='1.125rem'
							fontWeight='500'
							border={'0.125rem solid'}
							borderColor={'night_blue'}
							color='night_blue'
							transition={'background-color 0.25s ease-in-out'}
							_hover={{
								bg: 'night_blue',
								color: 'white',
							}}
							_active={{
								color: 'white',
								bg: 'night_blue',
							}}
							_focus={{
								boxShadow:
									'0 0 1px 2px #EB5B21, 0 1px 1px #EB5B21',
							}}
							_focusVisible={{
								outline: 'none',
								boxShadow:
									'0 0 1px 2px #EB5B21, 0 1px 1px #EB5B21',
							}}
						>
							<FontAwesomeIcon icon={faWhatsapp} /> Deel code via Whatsapp
						</Box>
						<Box
							onClick={shareMail}
							as='button'
							zIndex={'10'}
							display={'flex'}
							alignItems={'center'}
							justifyContent={'center'}
							gap={'0.6875rem'}
							ml={[0, 0, '0.75rem']}
							w={['100%', '100%', 'calc(50% - 0.75rem)']}
							height='3.5rem'
							borderRadius='0.4375rem'
							fontSize='1.125rem'
							fontWeight='500'
							border={'0.125rem solid'}
							borderColor={'night_blue'}
							color='night_blue'
							transition={'background-color 0.25s ease-in-out'}
							_hover={{
								bg: 'night_blue',
								color: 'white',
							}}
							_active={{
								color: 'white',
								bg: 'night_blue',
							}}
							_focus={{
								boxShadow:
									'0 0 1px 2px #EB5B21, 0 1px 1px #EB5B21',
							}}
							_focusVisible={{
								outline: 'none',
								boxShadow:
									'0 0 1px 2px #EB5B21, 0 1px 1px #EB5B21',
							}}
						>
							<FontAwesomeIcon icon={faEnvelope} /> Deel code via e-mail
						</Box>
						<Box
							onClick={checkGameState}
							className={`${styles.btn} ${refresh ? styles.refresh : ''}`}
							disabled={refresh}
							as='button'
							zIndex={'10'}
							display={'flex'}
							alignItems={'center'}
							justifyContent={'center'}
							gap={'0.6875rem'}
							mt={5}
							mb={2}
							ml={[0, 0, '0.75rem']}
							w={'100%'}
							height='3.5rem'
							borderRadius='0.4375rem'
							fontSize='1.125rem'
							fontWeight='500'
							bg='night_blue'
							color='white'
							transition={'background-color 0.25s ease-in-out'}
							_hover={{ bg: 'night_blue_dark' }}
							_active={{
								bg: 'night_blue_dark',
							}}
							_focus={{
								boxShadow:
									'0 0 1px 2px #EB5B21, 0 1px 1px #EB5B21',
							}}
							_focusVisible={{
								outline: 'none',
								boxShadow:
									'0 0 1px 2px #EB5B21, 0 1px 1px #EB5B21',
							}}
						>
							<FontAwesomeIcon className={styles.refreshicon} icon={faRefresh} /> Refresh
						</Box>
						<Box
							onClick={modalCancelGame}
							as='button'
							zIndex={'10'}
							display={'flex'}
							alignItems={'center'}
							justifyContent={'center'}
							gap={'0.6875rem'}
							mt={2}
							mb={4}
							ml={[0, 0, '0.75rem']}
							w={'100%'}
							height='3.5rem'
							borderRadius='0.4375rem'
							fontSize='1.125rem'
							fontWeight='500'
							bg='night_blue'
							color='white'
							transition={'background-color 0.25s ease-in-out'}
							_hover={{ bg: 'night_blue_dark' }}
							_active={{
								bg: 'night_blue_dark',
							}}
							_focus={{
								boxShadow:
									'0 0 1px 2px #EB5B21, 0 1px 1px #EB5B21',
							}}
							_focusVisible={{
								outline: 'none',
								boxShadow:
									'0 0 1px 2px #EB5B21, 0 1px 1px #EB5B21',
							}}
						>
							<FontAwesomeIcon icon={faBan} /> Spel annuleren
						</Box>
					</Flex>
					<Modal isOpen={isOpen} isCentered>
						<ModalOverlay />
						<ModalContent mx={7}>
							<ModalHeader px={7} pt={7} pb={1} fontSize={'1.75rem'} fontWeight={'bold'} color={'night_blue'}><FontAwesomeIcon style={{ marginRight: '0.3125rem' }} icon={faTriangleExclamation} /> Let op!</ModalHeader>
							<ModalBody>
								<Text fontSize={'1.125rem'} color={'night_blue'}>Je staat op het punt om je spel te annuleren, je kan niet meer meedoen met dit spel.</Text>
							</ModalBody>
							<ModalFooter pt={0} pb={7} flexWrap={'wrap'}>
								<Box
									onClick={cancelGame}
									as='button'
									mt={3}
									mb={5}
									zIndex={'10'}
									w={'100%'}
									height='3.5rem'
									borderRadius='0.4375rem'
									fontSize='1.125rem'
									fontWeight='500'
									bg='night_blue'
									color='white'
									transition={'background-color 0.25s ease-in-out'}
									_hover={{ bg: 'night_blue_dark' }}
									_active={{
										bg: 'night_blue_dark',
									}}
									_focus={{
										boxShadow:
											'0 0 1px 2px #EB5B21, 0 1px 1px #EB5B21',
									}}
									_focusVisible={{
										outline: 'none',
										boxShadow:
											'0 0 1px 2px #EB5B21, 0 1px 1px #EB5B21',
									}}
								>
									Spel annuleren
								</Box>
								<Box
									onClick={onClose}
									as='button'
									zIndex={'10'}
									display={'flex'}
									alignItems={'center'}
									justifyContent={'center'}
									w={'100%'}
									height='3.5rem'
									borderRadius='0.4375rem'
									fontSize='1.125rem'
									fontWeight='500'
									border={'0.125rem solid'}
									borderColor={'night_blue'}
									color='night_blue'
									transition={'background-color 0.25s ease-in-out'}
									_hover={{
										bg: 'night_blue',
										color: 'white',
									}}
									_active={{
										color: 'white',
										bg: 'night_blue',
									}}
									_focus={{
										boxShadow:
											'0 0 1px 2px #EB5B21, 0 1px 1px #EB5B21',
									}}
									_focusVisible={{
										outline: 'none',
										boxShadow:
											'0 0 1px 2px #EB5B21, 0 1px 1px #EB5B21',
									}}
								>
									Annuleren
								</Box>
							</ModalFooter>
						</ModalContent>
					</Modal>
				</Box>
			</Flex>
		</>
	)
}

const JoinGame = ({ setGroup }) => {
	const toast = useToast()

	const input01 = useRef(null)
	const input02 = useRef(null)
	const input03 = useRef(null)
	const input04 = useRef(null)
	const input05 = useRef(null)

	const [gameCode01, setGameCode01] = useState('')
	const [gameCode02, setGameCode02] = useState('')
	const [gameCode03, setGameCode03] = useState('')
	const [gameCode04, setGameCode04] = useState('')
	const [gameCode05, setGameCode05] = useState('')

	const [gameCodeError, setGameCodeError] = useState(false)

	const joinGameBtn = useRef(null)
	const [joinGameBtnLoading, setJoinGameBtnLoading] = useState(false)

	const { isOpen, onOpen, onClose } = useDisclosure()

	const handleGameCode01Change = (e) => {
		let str = e.target.value
		let shorten = str.slice(0, 1)
		setGameCode01(shorten)
		if (str.length > 0) {
			input02.current.focus()
		}
	}
	const handleGameCode02Change = (e) => {
		let str = e.target.value
		let shorten = str.slice(0, 1)
		setGameCode02(shorten)
		if (str.length > 0) {
			input03.current.focus()
		}
	}
	const handleGameCode03Change = (e) => {
		let str = e.target.value
		let shorten = str.slice(0, 1)
		setGameCode03(shorten)
		if (str.length > 0) {
			input04.current.focus()
		}
	}
	const handleGameCode04Change = (e) => {
		let str = e.target.value
		let shorten = str.slice(0, 1)
		setGameCode04(shorten)
		if (str.length > 0) {
			input05.current.focus()
		}
	}
	const handleGameCode05Change = (e) => {
		let str = e.target.value
		let shorten = str.slice(0, 1)
		setGameCode05(shorten)
		if (str.length > 0) {
			joinGameBtn.current.focus()
		}
	}

	useEffect(() => {
		if (gameCodeError) {
			toast({
				position: 'bottom',
				description: 'Game code is niet compleet.',
				status: 'error',
				duration: 4000,
			})
			setTimeout(() => {
				setGameCodeError(false)
			}, 4000)
		}
	}, [gameCodeError])

	const joinGame = () => {
		setGameCodeError(false)
		let gameCode = gameCode01 + gameCode02 + gameCode03 + gameCode04 + gameCode05

		if (gameCode.length === 5) {
			setJoinGameBtnLoading(true)
			axios.post(`${process.env.REACT_APP_BASE_URL}/user`, {
				nickname: localGetItem('nickName'),
				avatar_id: localGetItem('selectedAvatar'),
				game_id: JSON.parse(gameCode),
			})
				.then((response) => {
					localSetItem('gameCode', JSON.stringify(response.data.data.game_id))
					localSetItem('sessionId', response.data.data.session_id)
					localSetItem('playerTwoName', response.data.data.opponent.nickname)
					localSetItem('playerTwoAvatar', response.data.data.opponent.avatar_id)
					localSetItem('readyToPlay', true)
					setGroup('readytoplay')
				})
				.catch((error) => {
					if (error.response) {
						if (error.response.status === 400) {
							if (error.response.data.message === 'Game does not exist') {
								toast({
									position: 'bottom',
									description: 'Game code is ongeldig.',
									status: 'error',
									duration: 4000,
								})
								setJoinGameBtnLoading(false)
							} else {
								setJoinGameBtnLoading(false)
								onOpen(true)
							}
						} else {
							toast({
								position: 'bottom',
								description: 'Er ging iets mis, probeer het later opnieuw.',
								status: 'error',
								duration: 4000,
							})
							setJoinGameBtnLoading(false)
						}
					}
				})
		} else {
			setGameCodeError(true)
		}
	}

	const startNewGame = () => {
		onClose(true)
		setGroup('info')
	}

	return (
		<>
			<Flex flexWrap={'wrap'}>
				<Box zIndex={5} w={'100%'} mb={12}>
					<Text fontSize={'1.75rem'} line-height={'2.25rem'} fontWeight={'700'} color='white'>Je bent uitgenodigd!</Text>
					<Text mt={4} mb={4} fontSize={'1.125rem'} fontWeight={'bold'} color='night_blue'>Vul de unieke game code in om mee te doen met een bestaand spel.</Text>
					<Flex flexWrap={'wrap'} justifyContent={'space-between'} maxW={'20.625rem'}>
						<FormControl w={'3.625rem'} h={'3.5rem'} bg={'white'} borderRadius={'0.4375rem'} justifyContent={'center'} alignItems={'center'}>
							<Input ref={input01} value={gameCode01} onChange={handleGameCode01Change} w={'3.625rem'} h={'3.5rem'} border={0} fontSize={'1.75rem'} color={'night_blue'} fontWeight={'bold'} textTransform={'uppercase'} textAlign={'center'} type='txt' />
						</FormControl>
						<FormControl w={'3.625rem'} h={'3.5rem'} bg={'white'} borderRadius={'0.4375rem'} justifyContent={'center'} alignItems={'center'}>
							<Input ref={input02} value={gameCode02} onChange={handleGameCode02Change} w={'3.625rem'} h={'3.5rem'} border={0} fontSize={'1.75rem'} color={'night_blue'} fontWeight={'bold'} textTransform={'uppercase'} textAlign={'center'} type='txt' />
						</FormControl>
						<FormControl w={'3.625rem'} h={'3.5rem'} bg={'white'} borderRadius={'0.4375rem'} justifyContent={'center'} alignItems={'center'}>
							<Input ref={input03} value={gameCode03} onChange={handleGameCode03Change} w={'3.625rem'} h={'3.5rem'} border={0} fontSize={'1.75rem'} color={'night_blue'} fontWeight={'bold'} textTransform={'uppercase'} textAlign={'center'} type='txt' />
						</FormControl>
						<FormControl w={'3.625rem'} h={'3.5rem'} bg={'white'} borderRadius={'0.4375rem'} justifyContent={'center'} alignItems={'center'}>
							<Input ref={input04} value={gameCode04} onChange={handleGameCode04Change} w={'3.625rem'} h={'3.5rem'} border={0} fontSize={'1.75rem'} color={'night_blue'} fontWeight={'bold'} textTransform={'uppercase'} textAlign={'center'} type='txt' />
						</FormControl>
						<FormControl w={'3.625rem'} h={'3.5rem'} bg={'white'} borderRadius={'0.4375rem'} justifyContent={'center'} alignItems={'center'}>
							<Input ref={input05} value={gameCode05} onChange={handleGameCode05Change} w={'3.625rem'} h={'3.5rem'} border={0} fontSize={'1.75rem'} color={'night_blue'} fontWeight={'bold'} textTransform={'uppercase'} textAlign={'center'} type='txt' />
						</FormControl>
					</Flex>
				</Box>
				<Box mt={'auto'} w={'100%'}>
					<Box
						onClick={joinGame}
						ref={joinGameBtn}
						className={`${styles.btn} ${joinGameBtnLoading ? styles.loading : ''}`}
						disabled={joinGameBtnLoading}
						as='button'
						mt={4}
						mb={4}
						zIndex={'10'}
						w={'100%'}
						height='3.5rem'
						borderRadius='0.4375rem'
						fontSize='1.125rem'
						fontWeight='500'
						bg='night_blue'
						color='white'
						transition={'background-color 0.25s ease-in-out'}
						_hover={{ bg: 'night_blue_dark' }}
						_active={{
							bg: 'night_blue_dark',
						}}
						_focus={{
							boxShadow:
								'0 0 1px 2px #EB5B21, 0 1px 1px #EB5B21',
						}}
						_focusVisible={{
							outline: 'none',
							boxShadow:
								'0 0 1px 2px #EB5B21, 0 1px 1px #EB5B21',
						}}
					>
						<div className={styles.txt}>Deelnemen aan spel</div> <div className={styles.loader}><FontAwesomeIcon icon={faSpinnerThird} /></div>
					</Box>
				</Box>
			</Flex>
			<Modal isOpen={isOpen} isCentered>
				<ModalOverlay />
				<ModalContent mx={7}>
					<ModalHeader px={7} pt={7} pb={1} fontSize={'1.75rem'} fontWeight={'bold'} color={'night_blue'}>Oeps! Geen plek</ModalHeader>
					<ModalBody>
						<Text fontSize={'1.125rem'} color={'night_blue'}>Het spel waaraan je probeert deel te nemen zit al vol. Start zelf een nieuw spel of wacht op een nieuwe uitnodiging.</Text>
					</ModalBody>
					<ModalFooter pt={0} pb={7} flexWrap={'wrap'}>
						<Box
							onClick={startNewGame}
							as='button'
							mt={3}
							mb={5}
							zIndex={'10'}
							w={'100%'}
							height='3.5rem'
							borderRadius='0.4375rem'
							fontSize='1.125rem'
							fontWeight='500'
							bg='night_blue'
							color='white'
							transition={'background-color 0.25s ease-in-out'}
							_hover={{ bg: 'night_blue_dark' }}
							_active={{
								bg: 'night_blue_dark',
							}}
							_focus={{
								boxShadow:
									'0 0 1px 2px #EB5B21, 0 1px 1px #EB5B21',
							}}
							_focusVisible={{
								outline: 'none',
								boxShadow:
									'0 0 1px 2px #EB5B21, 0 1px 1px #EB5B21',
							}}
						>
							Nieuw spel starten
						</Box>
						<Box
							onClick={onClose}
							as='button'
							zIndex={'10'}
							display={'flex'}
							alignItems={'center'}
							justifyContent={'center'}
							w={'100%'}
							height='3.5rem'
							borderRadius='0.4375rem'
							fontSize='1.125rem'
							fontWeight='500'
							border={'0.125rem solid'}
							borderColor={'night_blue'}
							color='night_blue'
							transition={'background-color 0.25s ease-in-out'}
							_hover={{
								bg: 'night_blue',
								color: 'white',
							}}
							_active={{
								color: 'white',
								bg: 'night_blue',
							}}
							_focus={{
								boxShadow:
									'0 0 1px 2px #EB5B21, 0 1px 1px #EB5B21',
							}}
							_focusVisible={{
								outline: 'none',
								boxShadow:
									'0 0 1px 2px #EB5B21, 0 1px 1px #EB5B21',
							}}
						>
							Annuleren
						</Box>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	)
}

const ReadyToPlay = () => {
	const [gameCode, setGameCode] = useState(false)
	const [uniqueGameCode, setUniqueGameCode] = useState([])
	const [playerOneAvatar, setPlayerOneAvatar] = useState(0)
	const [playerOneName, setPlayerOneName] = useState(false)
	const [playerTwoAvatar] = useState(JSON.parse(localStorage.getItem('playerTwoAvatar')))
	const [playerTwoName] = useState(localGetItem('playerTwoName'))
	const avatars = GetAvatars()
	const navigate = useNavigate()

	useEffect(() => {
		if (localGetItem('gameCode')) {
			const code = localGetItem('gameCode')
			setGameCode(code)
		}
		setPlayerOneAvatar(JSON.parse(localGetItem('selectedAvatar')))
		setPlayerOneName(localGetItem('nickName'))
	}, [])

	useEffect(() => {
		if (gameCode) {
			setUniqueGameCode(gameCode.split(''))
		}
	}, [gameCode])

	const uniqueCode = uniqueGameCode.map((letter, index) =>
		<Flex key={index} w={'3.625rem'} h={'3.5rem'} bg={'white'} borderRadius={'0.4375rem'} justifyContent={'center'} alignItems={'center'} fontSize={'1.75rem'} color={'night_blue'} fontWeight={'bold'} textTransform={'uppercase'}>{letter}</Flex>
	)

	const profiles = (
		<Flex mt={[2, 2, 0]} mb={2} ml={'auto'} mr={'auto'} justifyContent={'space-between'} maxW={'20.625rem'}>
			<Box w={'50%'}>
				<Flex ml={'auto'} mr={'auto'} position={'relative'} w={'8.6875rem'} h={'8.6875rem'} alignItems={'center'} justifyContent={'center'}><Image position={'absolute'} w={'8.6875rem'} src={Select} alt='Select' /><Image position={'relative'} zIndex={2} w={'6.7356rem'} ml={'auto'} mr={'auto'} src={avatars[playerOneAvatar].image} alt={avatars[playerOneAvatar].alt} /></Flex>
				<Box textAlign={'center'}><Text fontSize={'1rem'} fontWeight={'bold'} color={'night_blue'} mt={3}>{playerOneName}</Text></Box>
			</Box>
			<Box w={'50%'}>
				<Flex ml={'auto'} mr={'auto'} position={'relative'} w={'8.6875rem'} h={'8.6875rem'} alignItems={'center'} justifyContent={'center'}><Image position={'absolute'} w={'8.6875rem'} src={Select} alt='Select' /><Image position={'relative'} zIndex={2} w={'6.7356rem'} ml={'auto'} mr={'auto'} src={avatars[playerTwoAvatar].image} alt={avatars[playerTwoAvatar].alt} /></Flex>
				<Box textAlign={'center'}><Text fontSize={'1rem'} fontWeight={'bold'} color={'night_blue'} mt={3}>{playerTwoName}</Text></Box>
			</Box>
		</Flex>
	)

	const startGame = () => {
		localSetItem('questions', true)
		navigate('/spelen/vragen')
	}

	return (
		<>
			<Box zIndex={5} w={'100%'} mb={[12, 12, 20]}>
				<Text fontSize={'1.75rem'} line-height={'2.25rem'} fontWeight={'700'} color='white'>Jullie zijn klaar om te beginnen!</Text>
				<Text mt={4} mb={2} fontSize={'1.125rem'} fontWeight={'bold'} color='night_blue'>Jouw unieke game code</Text>
				<Flex flexWrap={'wrap'} justifyContent={'space-between'} maxW={'20.625rem'}>
					{uniqueCode}
				</Flex>
			</Box>
			<Flex flexDirection="column" position={'relative'} mt={'auto'} mb={[-7, -7, -14]} w={'100%'} pt={[7, 7, 16]} pb={[7, 7, 16]}>
				<Box
					backgroundImage={`url(${navbarWave})`}
					className={styles.wave}
				/>
				<Box className={styles.bottomblockwhite} />
				<Box zIndex={20} position={'relative'} w={'100%'}>
					{profiles}
					<Flex mt={10} flexWrap={'wrap'}>
						<Box
							onClick={startGame}
							as='button'
							zIndex={'10'}
							display={'flex'}
							alignItems={'center'}
							justifyContent={'center'}
							gap={'0.6875rem'}
							mt={5}
							mb={4}
							ml={[0, 0, '0.75rem']}
							w={'100%'}
							height='3.5rem'
							borderRadius='0.4375rem'
							fontSize='1.125rem'
							fontWeight='500'
							bg='night_blue'
							color='white'
							transition={'background-color 0.25s ease-in-out'}
							_hover={{ bg: 'night_blue_dark' }}
							_active={{
								bg: 'night_blue_dark',
							}}
							_focus={{
								boxShadow:
									'0 0 1px 2px #EB5B21, 0 1px 1px #EB5B21',
							}}
							_focusVisible={{
								outline: 'none',
								boxShadow:
									'0 0 1px 2px #EB5B21, 0 1px 1px #EB5B21',
							}}
						>
							Spel starten
						</Box>
					</Flex>
				</Box>
			</Flex>
		</>
	)
}

const ResultEnd = ({ setGroup }) => {
	const navigate = useNavigate()
	const { isOpen, onOpen, onClose } = useDisclosure()

	const toStartNewGame = () => {
		onOpen(true)
	}

	const showResult = () => {
		navigate('/spelen/uitslag')
	}

	const startNewGame = () => {
		localRemoveItem('selectedAvatar')
		localRemoveItem('nickName')
		localRemoveItem('scorePlayer')
		localRemoveItem('result')
		localRemoveItem('playerTwoName')
		localRemoveItem('questions')
		localRemoveItem('gameCode')
		localRemoveItem('resultEnd')
		localRemoveItem('playerTwoAvatar')
		localRemoveItem('answers')
		localRemoveItem('readyToPlay')
		localRemoveItem('scorePlayerTwo')
		localRemoveItem('resultPlayerTwo')
		localRemoveItem('sessionId')
		onClose(true)
		setGroup('creator')
	}

	return (
		<>
			<Box zIndex={5} w={'100%'} mb={[12, 12, 20]}>
				<Text fontSize={'1.75rem'} line-height={'2.25rem'} fontWeight={'700'} color='white'>Je hebt de battle al eens gespeeld</Text>
				<Text mt={3} fontSize={'1.25rem'} color={'night_blue'}>Wil je deze nog een keer spelen? Of wil je de resultaten van de vorige battle bekijken?</Text>
			</Box>
			<Flex flexDirection="column" position={'relative'} mt={'auto'} mb={[-7, -7, -14]} w={'100%'} pt={[7, 7, 16]} pb={[7, 7, 16]}>
				<Box
					backgroundImage={`url(${navbarWave})`}
					className={styles.wave}
				/>
				<Box className={styles.bottomblockwhite} />
				<Box zIndex={20} position={'relative'} w={'100%'}>
					<Flex mt={2} flexWrap={'wrap'}>
						<Box
							onClick={toStartNewGame}
							as='button'
							zIndex={'10'}
							display={'flex'}
							alignItems={'center'}
							justifyContent={'center'}
							gap={'0.6875rem'}
							mb={5}
							w={'100%'}
							height='3.5rem'
							borderRadius='0.4375rem'
							fontSize='1.125rem'
							fontWeight='500'
							bg='night_blue'
							color='white'
							transition={'background-color 0.25s ease-in-out'}
							_hover={{ bg: 'night_blue_dark' }}
							_active={{
								bg: 'night_blue_dark',
							}}
							_focus={{
								boxShadow:
									'0 0 1px 2px #EB5B21, 0 1px 1px #EB5B21',
							}}
							_focusVisible={{
								outline: 'none',
								boxShadow:
									'0 0 1px 2px #EB5B21, 0 1px 1px #EB5B21',
							}}
						>
							Nieuw spel starten
						</Box>
						<Box
							onClick={showResult}
							as='button'
							zIndex={'10'}
							display={'flex'}
							alignItems={'center'}
							justifyContent={'center'}
							gap={'0.6875rem'}
							mb={4}
							w={'100%'}
							height='3.5rem'
							borderRadius='0.4375rem'
							fontSize='1.125rem'
							fontWeight='500'
							bg='white'
							border={'0.125rem solid'}
							borderColor={'night_blue'}
							color='night_blue'
							transition={'background-color 0.25s ease-in-out'}
							_hover={{
								bg: 'night_blue',
								color: 'white',
							}}
							_active={{
								bg: 'night_blue',
								color: 'white',
							}}
							_focus={{
								boxShadow:
									'0 0 1px 2px #EB5B21, 0 1px 1px #EB5B21',
							}}
							_focusVisible={{
								outline: 'none',
								boxShadow:
									'0 0 1px 2px #EB5B21, 0 1px 1px #EB5B21',
							}}
						>
							<FontAwesomeIcon className={styles.refreshicon} icon={faTrophy} /> Bekijk resultaten
						</Box>
					</Flex>
					<Modal isOpen={isOpen} isCentered>
						<ModalOverlay />
						<ModalContent mx={7}>
							<ModalHeader px={7} pt={7} pb={1} fontSize={'1.75rem'} fontWeight={'bold'} color={'night_blue'}><FontAwesomeIcon style={{ marginRight: '0.3125rem' }} icon={faTriangleExclamation} /> Let op!</ModalHeader>
							<ModalBody>
								<Text fontSize={'1.125rem'} color={'night_blue'}>Je gaat de battle nog een keer spelen. Als je een nieuw spel start, kun je de resultaten van het vorige spel niet meer bekijken.</Text>
							</ModalBody>
							<ModalFooter pt={0} pb={7} flexWrap={'wrap'}>
								<Box
									onClick={startNewGame}
									as='button'
									mt={3}
									mb={5}
									zIndex={'10'}
									w={'100%'}
									height='3.5rem'
									borderRadius='0.4375rem'
									fontSize='1.125rem'
									fontWeight='500'
									bg='night_blue'
									color='white'
									transition={'background-color 0.25s ease-in-out'}
									_hover={{ bg: 'night_blue_dark' }}
									_active={{
										bg: 'night_blue_dark',
									}}
									_focus={{
										boxShadow:
											'0 0 1px 2px #EB5B21, 0 1px 1px #EB5B21',
									}}
									_focusVisible={{
										outline: 'none',
										boxShadow:
											'0 0 1px 2px #EB5B21, 0 1px 1px #EB5B21',
									}}
								>
									Nieuw spel starten
								</Box>
								<Box
									onClick={onClose}
									as='button'
									zIndex={'10'}
									display={'flex'}
									alignItems={'center'}
									justifyContent={'center'}
									w={'100%'}
									height='3.5rem'
									borderRadius='0.4375rem'
									fontSize='1.125rem'
									fontWeight='500'
									border={'0.125rem solid'}
									borderColor={'night_blue'}
									color='night_blue'
									transition={'background-color 0.25s ease-in-out'}
									_hover={{
										bg: 'night_blue',
										color: 'white',
									}}
									_active={{
										color: 'white',
										bg: 'night_blue',
									}}
									_focus={{
										boxShadow:
											'0 0 1px 2px #EB5B21, 0 1px 1px #EB5B21',
									}}
									_focusVisible={{
										outline: 'none',
										boxShadow:
											'0 0 1px 2px #EB5B21, 0 1px 1px #EB5B21',
									}}
								>
									Annuleren
								</Box>
							</ModalFooter>
						</ModalContent>
					</Modal>
				</Box>
			</Flex>
		</>
	)
}

export default PlayPage
