import { useEffect, useState } from 'react'

import CheckWebpFeature from '../hooks/CheckWebpFeature'
import Avatar01Webp from '../assets/avatars/avatar01.webp'
import Avatar01 from '../assets/avatars/avatar01.png'
import Avatar02Webp from '../assets/avatars/avatar02.webp'
import Avatar02 from '../assets/avatars/avatar02.png'
import Avatar03Webp from '../assets/avatars/avatar03.webp'
import Avatar03 from '../assets/avatars/avatar03.png'
import Avatar04Webp from '../assets/avatars/avatar04.webp'
import Avatar04 from '../assets/avatars/avatar04.png'
import Avatar05Webp from '../assets/avatars/avatar05.webp'
import Avatar05 from '../assets/avatars/avatar05.png'
import Avatar06Webp from '../assets/avatars/avatar06.webp'
import Avatar06 from '../assets/avatars/avatar06.png'
import Avatar07Webp from '../assets/avatars/avatar07.webp'
import Avatar07 from '../assets/avatars/avatar07.png'
import Avatar08Webp from '../assets/avatars/avatar08.webp'
import Avatar08 from '../assets/avatars/avatar08.png'
import Avatar09Webp from '../assets/avatars/avatar09.webp'
import Avatar09 from '../assets/avatars/avatar09.png'
import Avatar10Webp from '../assets/avatars/avatar10.webp'
import Avatar10 from '../assets/avatars/avatar10.png'
import Avatar11Webp from '../assets/avatars/avatar11.webp'
import Avatar11 from '../assets/avatars/avatar11.png'
import Avatar12Webp from '../assets/avatars/avatar12.webp'
import Avatar12 from '../assets/avatars/avatar12.png'

const GetAvatars = () => {
	const [avatar01, setAvatar01] = useState('')
	const [avatar02, setAvatar02] = useState('')
	const [avatar03, setAvatar03] = useState('')
	const [avatar04, setAvatar04] = useState('')
	const [avatar05, setAvatar05] = useState('')
	const [avatar06, setAvatar06] = useState('')
	const [avatar07, setAvatar07] = useState('')
	const [avatar08, setAvatar08] = useState('')
	const [avatar09, setAvatar09] = useState('')
	const [avatar10, setAvatar10] = useState('')
	const [avatar11, setAvatar11] = useState('')
	const [avatar12, setAvatar12] = useState('')

	useEffect(() => {
		if (CheckWebpFeature) {
			setAvatar01(Avatar01Webp)
			setAvatar02(Avatar02Webp)
			setAvatar03(Avatar03Webp)
			setAvatar04(Avatar04Webp)
			setAvatar05(Avatar05Webp)
			setAvatar06(Avatar06Webp)
			setAvatar07(Avatar07Webp)
			setAvatar08(Avatar08Webp)
			setAvatar09(Avatar09Webp)
			setAvatar10(Avatar10Webp)
			setAvatar11(Avatar11Webp)
			setAvatar12(Avatar12Webp)
		} else {
			setAvatar01(Avatar01)
			setAvatar02(Avatar02)
			setAvatar03(Avatar03)
			setAvatar03(Avatar04)
			setAvatar03(Avatar05)
			setAvatar03(Avatar06)
			setAvatar03(Avatar07)
			setAvatar03(Avatar08)
			setAvatar03(Avatar09)
			setAvatar03(Avatar10)
			setAvatar03(Avatar11)
			setAvatar03(Avatar12)
		}
	}, [])

	let avatars = [
		{
			alt: 'Avatar',
			image: avatar01,
		},
		{
			alt: 'Avatar',
			image: avatar02,
		},
		{
			alt: 'Avatar',
			image: avatar03,
		},
		{
			alt: 'Avatar',
			image: avatar04,
		},
		{
			alt: 'Avatar',
			image: avatar05,
		},
		{
			alt: 'Avatar',
			image: avatar06,
		},
		{
			alt: 'Avatar',
			image: avatar07,
		},
		{
			alt: 'Avatar',
			image: avatar08,
		},
		{
			alt: 'Avatar',
			image: avatar09,
		},
		{
			alt: 'Avatar',
			image: avatar10,
		},
		{
			alt: 'Avatar',
			image: avatar11,
		},
		{
			alt: 'Avatar',
			image: avatar12,
		},
	]

	return avatars
}

export default GetAvatars
