import React from 'react'
import { Container, Flex, Box, Heading, Text } from '@chakra-ui/react'
import styles from './Page.module.scss'

import navbarWave from '../assets/navbar-wave-bottom.svg'
import navbarWavBlue from '../assets/navbar-wave-bottom-blue.svg'

const PrivacyPage = () => {
	return (
		<>
			<Container p={0} maxW='container.sm' className={styles.pagecontainer} position={'relative'}>
				<Flex flexDirection="column" minH={'calc(100vh - 3.75rem)'}>
					<Flex flex={'wrap'} flexDirection="column" position={'relative'} w={'100%'}>
						<Box
							backgroundImage={`url(${navbarWavBlue})`}
							className={styles.wave}
						/>
						<Box className={styles.bottomblockblue} />
						<Box w={'100%'} pt={[7, 7, 16]} pb={[4, 4, 16]}>
							<Box zIndex={20} position={'relative'} w={'100%'} pl={7} pr={7} pb={7}>
								<Box>
									<Heading color={'white'} fontSize={'1.75rem'} fontWeight={'700'} mb={4}>Disclaimer</Heading>
								</Box>
							</Box>
						</Box>
					</Flex>
					<Flex className={styles.blockbottom} flex={'wrap'} flexDirection="column" position={'relative'} w={'100%'} mt={'0.5625rem'}>
						<Box
							backgroundImage={`url(${navbarWave})`}
							className={styles.wave}
						/>
						<Box className={styles.bottomblockwhite} />
						<Box w={'100%'} pt={[7, 7, 16]} pb={[7, 7, 16]}>
							<Box zIndex={20} position={'relative'} w={'100%'} pl={7} pr={7}>
								<Box mt={1}>
									<Text>Sexy en Safe is een initiatief van Sociaal Domein Fryslân, een samenwerkingsorganisatie van alle Friese gemeenten gericht op jongeren, ouders en docenten De inhoud van deze website is vertrouwelijk en uitsluitend bestemd voor jou als gebruiker. Meer weten? Ga naar: <a href='https://www.sdfryslan.nl/privacyverklaring' className={styles.link} title='Privacyverklaring' target='_blank' rel='noreferrer'>sdfryslan.nl/privacyverklaring</a></Text>
								</Box>
							</Box>
						</Box>
					</Flex>
				</Flex>
			</Container>
		</>
	)
}
export default PrivacyPage
