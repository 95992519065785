import React from 'react'
import HomePage from '../components/HomePage'

const Home = () => {
	return (
		<>
			<HomePage />
		</>
	)
}
export default Home
