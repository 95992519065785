import React from 'react'
import PlayPage from '../components/PlayPage'

const Play = () => {
	return (
		<>
			<PlayPage />
		</>
	)
}
export default Play
