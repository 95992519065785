import React from 'react'
import { Container, Flex, Box, Text, Heading, UnorderedList, ListItem } from '@chakra-ui/react'
import styles from './Page.module.scss'

import navbarWave from '../assets/navbar-wave-bottom.svg'
import navbarWavBlue from '../assets/navbar-wave-bottom-blue.svg'

const TipsAndTricksPage = () => {
	return (
		<>
			<Container p={0} maxW='container.sm' className={styles.pagecontainer} position={'relative'}>
				<Flex flexDirection="column" minH={'calc(100vh - 3.75rem)'}>
					<Flex flex={'wrap'} flexDirection="column" position={'relative'} w={'100%'}>
						<Box
							backgroundImage={`url(${navbarWavBlue})`}
							className={styles.wave}
						/>
						<Box className={styles.bottomblockblue} />
						<Box w={'100%'} pt={[7, 7, 16]} pb={[4, 4, 16]}>
							<Box zIndex={20} position={'relative'} w={'100%'} pl={7} pr={7} pb={7}>
								<Box>
									<Heading color={'white'} fontSize={'1.75rem'} fontWeight={'700'} mb={4}>Zo ben jij veilig online</Heading>
									<Text color={'night_blue'} fontStyle={'1.25rem'} mb={4}>Aan sexting zitten risico&rsquo;s. Maar met deze tips maak je die risico&rsquo;s een stuk kleiner en ben jij veilig(er) online.</Text>
									<UnorderedList color={'night_blue'} fontStyle={'1.25rem'}>
										<ListItem>Zorg dat je niet herkenbaar bent op de foto of video. Dat doe je bijvoorbeeld door je gezicht, slaapkamer of herkenbare tattoos niet in beeld te brengen.</ListItem>
										<ListItem>Verstuur alleen een foto als jij dat zelf wilt. Laat je dus niet onder druk zetten om dat te doen.</ListItem>
										<ListItem>Spreek met de persoon naar wie je de sext stuurt, af dat hij de foto of video meteen verwijdert.</ListItem>
										<ListItem>Ontvang je een sexy foto of video van iemand anders? Stuur die dan nooit door. Dat is zelfs strafbaar. </ListItem>
									</UnorderedList>
								</Box>
							</Box>
						</Box>
					</Flex>
					<Flex className={styles.blockbottom} flex={'wrap'} flexDirection="column" position={'relative'} w={'100%'} mt={'0.5625rem'}>
						<Box
							backgroundImage={`url(${navbarWave})`}
							className={styles.wave}
						/>
						<Box className={styles.bottomblockwhite} />
						<Box w={'100%'} pt={[7, 7, 16]} pb={[7, 7, 16]}>
							<Box zIndex={20} position={'relative'} w={'100%'} pl={7} pr={7}>
								<Box mt={1}>
									<Heading color={'night_blue'} fontSize={'1.75rem'} fontWeight={'700'} mb={4}>Dit kun je als ouder doen</Heading>
									<Text color={'night_blue'} fontStyle={'1.25rem'} mb={4}>Het kan lastig zijn om met je kind te praten over seks en sexting. Toch is dat belangrijk om te doen. De Sexting Battle kan een goed startpunt zijn. Bespreek bijvoorbeeld de stellingen waarop jullie verschillend hebben geantwoord. Of vraag je kind om zijn of haar antwoord op een bepaalde stelling toe te lichten.</Text>
									<Text color={'night_blue'} fontStyle={'1.25rem'} mb={8}>Uit onderzoek blijkt dat jongeren die thuis open over seks en online veiligheid praten, sneller aan hun ouders vertellen dat ze een naaktfoto of -video hebben gekregen. Ook blijkt dat ze kritischer zijn over de dingen die ze op sociale media zien en meemaken. </Text>
									<Heading color={'night_blue'} fontSize={'1.75rem'} fontWeight={'700'} mb={4}>Gaat het toch mis?</Heading>
									<Text color={'night_blue'} fontStyle={'1.25rem'}>Worden er naaktbeelden van je kind doorgestuurd of stuurt je kind zelf naaktbeelden? Geef je kind dan nooit de schuld, maar steun hem of haar en onderneem direct actie. Bijvoorbeeld door contact op te nemen met de vertrouwenspersoon op school.</Text>
								</Box>
							</Box>
						</Box>
					</Flex>
				</Flex>
			</Container>
		</>
	)
}
export default TipsAndTricksPage
