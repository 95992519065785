import React from 'react'
import ResultPage from '../components/ResultPage'

const Result = () => {
	return (
		<>
			<ResultPage />
		</>
	)
}
export default Result
