import React from 'react'
import { Container, Flex, Box, Image, Stack, Text } from '@chakra-ui/react'
import styles from './HomePage.module.scss'
import { useNavigate } from 'react-router-dom'

import WhatIsFake from '../assets/whatsnep.svg'
import navbarWave from '../assets/navbar-wave-bottom.svg'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLightbulbOn, faCircleInfo } from '@fortawesome/pro-regular-svg-icons'

const HomePage = () => {
	return (
		<>
			<HomeIntro />
		</>
	)
}

const HomeIntro = () => {
	const navigate = useNavigate()

	const playGame = () => {
		navigate('/spelen')
	}

	const tipsAndTricks = () => {
		navigate('/tips-en-tricks')
	}
	
	const info = () => {
		navigate('/informatie')
	}

	return (
		<>
			<Container p={0} maxW='container.sm'>
				<Flex flexDirection="column">
					<Flex justifyContent="center" alignItems="center" flexDirection="column" pt={[7, 7, 24]} pb={[16, 16, 24]} pl={12} pr={12}>
						<Image w={'100%'} maxW={'31.25rem'} src={WhatIsFake} alt='What&apos;s nep?' htmlWidth={'31.25rem'} htmlHeight={'21.5594rem'} />
						<Stack spacing={3} pt={[9, 9, 16]}>
							<Text fontWeight={'500'} fontSize={'1.25rem'} color={'night_blue'}>What&rsquo;s nep en wat niet? Speel samen met je kind(eren) de socialbattle en ontdek alles over de wondere (online) wereld van je kind!</Text>
						</Stack>
					</Flex>
					<Flex position={'relative'} flexWrap='wrap' justifyContent="center" alignItems="center" pt={[7, 7, 16]} pb={[7, 7, 16]} pl={7} pr={7}>
						<Box
							backgroundImage={`url(${navbarWave})`}
							className={styles.wave}
						/>
						<Box className={styles.bottomblock} />
						<Box
							onClick={playGame}
							as='button'
							mb={6}
							zIndex={'10'}
							w={'100%'}
							height='4.75rem'
							borderRadius='0.4375rem'
							fontSize='1.75rem'
							fontWeight='600'
							bg='night_blue'
							color='white'
							transition={'background-color 0.25s ease-in-out'}
							_hover={{ bg: 'night_blue_dark' }}
							_active={{
								bg: 'night_blue_dark',
							}}
							_focus={{
								boxShadow:
									'0 0 1px 2px #EB5B21, 0 1px 1px #EB5B21',
							}}
							_focusVisible={{
								outline: 'none',
								boxShadow:
									'0 0 1px 2px #EB5B21, 0 1px 1px #EB5B21',
							}}
						>
							Speel het spel
						</Box>
						<Box
							onClick={tipsAndTricks}
							as='button'
							zIndex={'10'}
							mb={[2, 2, 0]}
							display={'flex'}
							alignItems={'center'}
							justifyContent={'center'}
							gap={'0.6875rem'}
							mr={[0, 0, '0.75rem']}
							w={['100%', '100%', 'calc(50% - 0.75rem)']}
							height='3.5rem'
							borderRadius='0.4375rem'
							fontSize='1.125rem'
							fontWeight='500'
							border={'0.125rem solid'}
							borderColor={'night_blue'}
							color='night_blue'
							transition={'background-color 0.25s ease-in-out'}
							_hover={{
								bg: 'night_blue',
								color: 'white',
							}}
							_active={{
								color: 'white',
								bg: 'night_blue',
							}}
							_focus={{
								boxShadow:
									'0 0 1px 2px #EB5B21, 0 1px 1px #EB5B21',
							}}
							_focusVisible={{
								outline: 'none',
								boxShadow:
									'0 0 1px 2px #EB5B21, 0 1px 1px #EB5B21',
							}}
						>
							<FontAwesomeIcon icon={faLightbulbOn} /> Tips &amp; Tricks
						</Box>
						<Box
							onClick={info}
							as='button'
							zIndex={'10'}
							display={'flex'}
							alignItems={'center'}
							justifyContent={'center'}
							gap={'0.6875rem'}
							ml={[0, 0, '0.75rem']}
							w={['100%', '100%', 'calc(50% - 0.75rem)']}
							height='3.5rem'
							borderRadius='0.4375rem'
							fontSize='1.125rem'
							fontWeight='500'
							border={'0.125rem solid'}
							borderColor={'night_blue'}
							color='night_blue'
							transition={'background-color 0.25s ease-in-out'}
							_hover={{
								bg: 'night_blue',
								color: 'white',
							}}
							_active={{
								color: 'white',
								bg: 'night_blue',
							}}
							_focus={{
								boxShadow:
									'0 0 1px 2px #EB5B21, 0 1px 1px #EB5B21',
							}}
							_focusVisible={{
								outline: 'none',
								boxShadow:
									'0 0 1px 2px #EB5B21, 0 1px 1px #EB5B21',
							}}
						>
							<FontAwesomeIcon icon={faCircleInfo} /> Meer informatie
						</Box>
					</Flex>
				</Flex>
			</Container>
		</>
	)
}

export default HomePage
