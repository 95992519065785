import { extendTheme } from '@chakra-ui/react'
import './_fonts.scss'

export const theme = extendTheme({
	fonts: {
		heading: 'Poppins, sans-serif',
		body: 'Poppins, sans-serif',
	},
	colors: {
		orange_red: '#EB5B21',
		pale_pink: '#F8C8D8',
		night_blue: '#31348B',
		night_blue_dark: '#0B0F6A',
		happy_blue: '#4CC2F1',
		red: {
			300: '#E53E3E',
		}
	}
})
