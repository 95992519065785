import React from 'react'
import QuestionsPage from '../components/QuestionsPage'

const Questions = () => {
	return (
		<>
			<QuestionsPage />
		</>
	)
}
export default Questions
