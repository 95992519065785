import React, { useEffect } from 'react'
import {
	Box,
	Flex,
	Text,
	IconButton,
	Stack,
	Collapse,
} from '@chakra-ui/react'
import navbarWave from '../assets/navbar-wave.svg'
import { NavLink } from 'react-router-dom'
import styles from './Header.module.scss'

const header = ({ isOpen, onToggle, hamburgerColor, navbarDesktopColor }) => {

	useEffect(() => {
		if (isOpen) {
			document.body.style.overflow = 'hidden'
			document.documentElement.style.overflow = 'hidden'
		} else {
			document.body.style.overflow = 'auto'
			document.documentElement.style.overflow = 'auto'
		}
	}, [isOpen])

	return (
		<Box>
			<Flex
				zIndex={150}
				minH={'3.75rem'}
				py={{ base: 2 }}
				px={{ base: 4 }}
				align={'center'}
				position={'relative'}>
				<Flex flex={{ base: 1 }} justify={{ base: 'center', lg: 'start' }}>
					<Flex display={{ base: 'none', lg: 'flex' }} w={'100%'}>
						<DesktopNav navbarDesktopColor={navbarDesktopColor} />
					</Flex>
				</Flex>
				<Flex
					flex={{ base: 1, lg: 'auto' }}
					ml={{ base: -2 }}
					display={{ base: 'flex', lg: 'none' }}>
					<IconButton
						onClick={onToggle}
						icon={
							isOpen ? <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg"><path d="M24 2.35 21.65 0 12 9.65 2.35 0 0 2.35 9.65 12 0 21.65 2.35 24 12 14.35 21.65 24 24 21.65 14.35 12z" fill="#31348B" fillRule="evenodd" /></svg> : <svg className={`${styles.hamburger} ${hamburgerColor == 'white' ? styles.hamburgerwhite : '' || hamburgerColor == 'orange_red' ? styles.hamburgerorangered : ''}`} width="28" height="25" xmlns="http://www.w3.org/2000/svg"><path d="M0 0v3.676h28V0H0Zm0 10.662v3.676h28v-3.676H0Zm0 10.662V25h28v-3.676H0Z" fill="#31348B" fillRule="evenodd" /></svg>
						}
						aria-label={'Toggle Navigation'}
						ml={'auto'}
						bgColor={'transparent'}
						color={'white'}
						_active={{
							bg: 'transparent',
						}}
						_hover={{
							bg: 'transparent',
						}}
					/>
				</Flex>
			</Flex>
			<Collapse in={isOpen} animateOpacity duration={250} className={styles.collapse}>
				<MobileNav onToggle={onToggle} />
			</Collapse>
		</Box>
	)
}

const DesktopNav = ({ navbarDesktopColor }) => {
	return (
		<>
			<Stack direction={'row'} spacing={4} w={'100%'}>
				{NAV_ITEMS.map((navItem) => (
					<Box key={navItem.label}>
						<NavLink
							p={4}
							to={navItem.to}
							fontSize={'sm'}
							fontWeight={500}
							className={({ isActive }) => styles.link + ' ' + (isActive ? ` ${styles.active}` : '')}
						>
							<span className={`${styles.link} ${navbarDesktopColor == 'night_blue' ? styles.itemnightblue : '' || navbarDesktopColor == 'orange_red' ? styles.itemorangered : '' || navbarDesktopColor == 'white' ? styles.itemwhite : ''}`}>{navItem.label}</span>
						</NavLink>
					</Box>
				))}
			</Stack>
			<Stack direction={'row'} spacing={4} w={'100%'}>
				{NAV_ITEMS_BOTTOM.map((navItem) => (
					<Box key={navItem.label} ml={'auto'}>
						<NavLink
							p={4}
							to={navItem.to}
							fontSize={'sm'}
							fontWeight={500}
							className={({ isActive }) => styles.link + ' ' + (isActive ? ` ${styles.active}` : '')}
						>
							<span className={`${styles.link} ${navbarDesktopColor == 'night_blue' ? styles.itemnightblue : '' || navbarDesktopColor == 'orange_red' ? styles.itemorangered : '' || navbarDesktopColor == 'white' ? styles.itemwhite : ''}`}>{navItem.label}</span>
						</NavLink>
					</Box>
				))}
			</Stack>
		</>
	)
}

const MobileNav = ({ onToggle }) => {
	return (
		<Stack
			bg={'night_blue'}
			display={{ lg: 'none' }}
			minH={'calc(100vh - 3.75rem)'}
			className={styles.mobilemav}
			position={'relative'}
		>
			<Box 
				backgroundImage={`url(${navbarWave})`}
				className={styles.wave}
			/>
			<Flex flexDirection={'column'} h={'calc(100vh - (3rem * 2))'} pl={4} pr={4} pt={12} pb={0} overflowY={'auto'}>
				{NAV_ITEMS.map((navItem) => (
					<MobileNavItem onToggle={onToggle} key={navItem.label} {...navItem} />
				))}
				<Flex mt={'auto'} pt={4} flexDirection={'column'}>
					{NAV_ITEMS_BOTTOM.map((navItem) => (
						<MobileNavItemBottom onToggle={onToggle} key={navItem.label} {...navItem} />
					))}
				</Flex>
			</Flex>
		</Stack>
	)
}

const MobileNavItem = ({ onToggle, label, to }) => {
	const closeCollapse = () => {
		onToggle(false)
	}

	return (
		<Stack spacing={4}>
			<NavLink
				className={({ isActive }) => styles.link + ' ' + (isActive ? ` ${styles.active}` : '')}
				to={to}
				onClick={closeCollapse}
			>
				<Text
					py={2}
					px={8}
					fontWeight={600}
					fontSize={'1.5rem'}
					color={'white'}>
					{label}
				</Text>
			</NavLink>
		</Stack>
	)
}

const MobileNavItemBottom = ({ onToggle, label, to }) => {
	const closeCollapse = () => {
		onToggle(false)
	}

	return (
		<Stack spacing={4}>
			<NavLink
				className={({ isActive }) => styles.link + ' ' + (isActive ? ` ${styles.active}` : '')}
				to={to}
				onClick={closeCollapse}
			>
				<Text
					py={2}
					px={8}
					fontWeight={600}
					fontSize={'1.125rem'}
					color={'white'}>
					{label}
				</Text>
			</NavLink>
		</Stack>
	)
}

const NAV_ITEMS = [
	{
		label: 'Home',
		to: '/',
	},
	{
		label: 'Spelen',
		to: '/spelen',
	},
	{
		label: 'Tips & Tricks',
		to: '/tips-en-tricks',
	},
	{
		label: 'Meer informatie',
		to: '/informatie',
	},
]

const NAV_ITEMS_BOTTOM = [
	{
		label: 'Privacyvoorwaarden',
		to: '/privacyvoorwaarden',
	},
	{
		label: 'Disclaimer',
		to: '/disclaimer',
	},
]

export default header
