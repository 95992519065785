import React, { useEffect, useState } from 'react'
import Header from './components/Header'
import { BrowserRouter, useLocation, useSearchParams } from 'react-router-dom'
import { localSetItem } from './hooks/LocalStorage'
import ScrollToTop from './components/ScrollToTop'
import Switch from './components/Switch'
import { ChakraProvider, useDisclosure, Box } from '@chakra-ui/react'
import { theme } from './styles/theme'

const App = () => {
	const { isOpen, onToggle } = useDisclosure()
	const [bgColor, setBgcolor] = useState('happy_blue')
	const [hamburgerColor, setHamburgerColor] = useState('night_blue')
	const [navbarDesktopColor, setNavbarDesktopColor] = useState('night_blue')
	const [firstLoad, setFirstLoad] = useState(true)

	const PageColorMixing = () => {
		const location = useLocation()
		let [searchParams, setSearchParams] = useSearchParams()

		const navOpen = () => {
			setBgcolor('happy_blue')
			setHamburgerColor('night_blue')
			setNavbarDesktopColor('night_blue')
		}

		useEffect(() => {
			if (location.pathname == '/') {
				if (isOpen) {
					navOpen()
				} else {
					setBgcolor('happy_blue')
					setHamburgerColor('night_blue')
					setNavbarDesktopColor('night_blue')
				}
			} else if (location.pathname == '/spelen') {
				if (isOpen) {
					navOpen()
				} else {
					setBgcolor('night_blue')
					setHamburgerColor('white')
					setNavbarDesktopColor('white')
				}
			} else if (location.pathname == '/spelen/vragen') {
				if (isOpen) {
					navOpen()
				} else {
					setBgcolor('pale_pink')
					setHamburgerColor('orange_red')
					setNavbarDesktopColor('orange_red')
				}
			} else if (location.pathname == '/spelen/uitslag') {
				if (isOpen) {
					navOpen()
				} else {
					setBgcolor('pale_pink')
					setHamburgerColor('orange_red')
					setNavbarDesktopColor('orange_red')
				}
			} else if (location.pathname == '/tips-en-tricks') {
				if (isOpen) {
					navOpen()
				} else {
					setBgcolor('night_blue')
					setHamburgerColor('white')
					setNavbarDesktopColor('white')
				}
			} else if (location.pathname == '/informatie') {
				if (isOpen) {
					navOpen()
				} else {
					setBgcolor('night_blue')
					setHamburgerColor('white')
					setNavbarDesktopColor('white')
				}
			} else if (location.pathname == '/privacyvoorwaarden') {
				if (isOpen) {
					navOpen()
				} else {
					setBgcolor('night_blue')
					setHamburgerColor('white')
					setNavbarDesktopColor('white')
				}
			} else if (location.pathname == '/disclaimer') {
				if (isOpen) {
					navOpen()
				} else {
					setBgcolor('night_blue')
					setHamburgerColor('white')
					setNavbarDesktopColor('white')
				}
			} else {
				if (isOpen) {
					navOpen()
				} else {
					setBgcolor('happy_blue')
					setHamburgerColor('night_blue')
					setNavbarDesktopColor('night_blue')
				}
			}

			if (searchParams.has('sessionId')) {
				localSetItem('mediaMastersSessionId', searchParams.get('sessionId'))
				searchParams.delete('sessionId')
				setSearchParams(searchParams)
			}

		}, [location, isOpen])
	}

	useEffect(() => {
		setTimeout(() => {
			setFirstLoad(false)
		}, 500)
	}, [])

	return (
		<BrowserRouter>
			<PageColorMixing />
			<ScrollToTop />
			<ChakraProvider theme={theme}>
				<Box className={firstLoad ? 'firstload' : ''} bg={bgColor} minH={'100vh'} transition={'background-color 0.5s ease'} overflow={'hidden'}>
					<Header isOpen={isOpen} onToggle={onToggle} hamburgerColor={hamburgerColor} navbarDesktopColor={navbarDesktopColor} />
					<main>
						<Switch />
					</main>
				</Box>
			</ChakraProvider>
		</BrowserRouter>
	)
}

export default App
