import React from 'react'
import DisclaimerPage from '../components/DisclaimerPage'

const Disclaimer = () => {
	return (
		<>
			<DisclaimerPage />
		</>
	)
}
export default Disclaimer
